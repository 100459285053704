import React, { Component } from 'react';
import { FormGroup } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { validateAccess } from '../../session';
import { eventsLoaded, eventsUpdate, usersLoaded, addRecentlyCreated } from '../../actions';
import {
  Button,
  DropdownInput,
  RadioButton,
  GenericInput,
  ImageInput,
  OverlayPage,
  OverlayPageContents,
  OverlayPageSection,
  OverlayPageBottomButtons,
  DatePicker,
  TimePicker,
  UserListing,
  P60Icon,
  SuccessPopup,
  CheckBox,
  AddUserPopup,
  TextFormatPopup,
  OptionsSection,
  FileInput,
  Popup,
  NumberSpinner,
  SVGIcon,
  ProfilePic,
  Tag,
  LinkedContentSelector,
  AudienceSelector,
  ExportCsvPopup,
  ReactRRuleGenerator,
} from '../../components';
import { eventActions, userActions, paymentActions } from '../../webapi';
import {
  safeReadParams,
  getThumb300,
  get1400,
  isEmail,
  getDateFromTimeDatePickers,
  getUrlParams,
  isContentSource,
  getFileName,
  getPluralS,
  getTimepickerTime,
  getSiteSettingFromState,
  getSiteLevelFromState,
  getMerchantsFromState,
  onlyAlphanumeric,
} from '../../helper';
import { eventPayments, withPay } from '../../payment';
import { COLOUR_GRAPEFRUIT, COLOUR_BRANDING_MAIN, hexToRGBAstring } from '../../js';
import categories from '../../json/eventCategories.json';
import bookingTypes from '../../json/eventBookingTypes.json';
import { eventsHaveTags, defaultProfileImage, DEFAULT_ALLOW_COMMENTS } from '../../config';
import { Text } from '../../components/text';
import { MakerPopup } from '../../components/MakerPopup';
import SendAlertPopup from './SendAlertPopup';

class AddEvent extends Component {
  constructor(props) {
    super(props);
    this.initialAddAttendee = {
      addingAttendees: false,
      selectedAttendee: null,
      attendanceCount: 0,
      attendanceNotes: '',
      attendeeSearch: '',
      spotsAvailable: null,
      loadingSpots: false,
      savingAttendee: false,
    };

    this.initialRefund = {
      attendeeToRefund: null,
      refundTransactions: [],
      selectedTransaction: null,
      ticketsEntered: [],
    };

    this.initialAttendance = {
      selectedTimeSlot: null,
      eventAttendees: [],
      repPayments: {},
      loadingPayments: false,
      loadingReps: false,
      cancellingAttendee: false,
      cancellingAttendeeId: null,
      ..._.cloneDeep(this.initialAddAttendee),
      ..._.cloneDeep(this.initialRefund),
    };

    this.exportColumns = [
      { label: 'Select All', key: '' },
      { label: 'Event', key: 'Title' },
      { label: 'Date', key: 'Date' },
      { label: 'Time', key: 'Time' },
      { label: 'Name', key: 'displayName' },
      { label: 'Unit', key: 'Unit' },
      { label: 'User ID', key: 'id' },
      { label: 'Registration Time', key: 'Timestamp' },
      { label: 'Number of Spots', key: 'Count' },
      { label: 'Notes', key: 'Notes' },
    ];

    this.paidExportColumns = [
      { label: 'Tickets', key: 'Tickets' },
      { label: 'Transaction Id', key: 'TransactionId' },
      { label: 'Amount Paid', key: 'Amount' },
    ];

    const eventId = safeReadParams(this.props, 'eventId');
    let eventIdWithoutSite = null;
    if (eventId) {
      eventIdWithoutSite = _.last(eventId.split('_'));
    }

    this.initialState = {
      eventType: window.location.pathname.indexOf('/facilities') === 0 ? 'FACILITY' : 'EVENT',
      eventId,
      eventIdWithoutSite,
      eventTitle: '',
      eventDescription: '',
      //eventImage: '',
      eventLocation: '',
      eventTag: '',
      eventSecondaryTags: '',
      eventLink: '',
      eventTicketLink: '',
      eventTicketDetails: {
        merchant: '',
        categories: [],
      },
      eventGPS: '',
      eventFF: false,
      eventSpotify: '',
      area: 'Global',
      showWarnings: false,
      success: false,
      submitting: false,
      eventRepeats: false,
      eventRepetitions: [],
      eventAttachments: [],
      uploadingImage: false,
      isEveryday: window.location.pathname.indexOf('/facilities') === 0 ? true : false,
      isFeatured: false,
      isTvMode: true,
      phone: '',
      organiser: '',

      locationTagInput: '',
      locationTags: [],
      availableLocationTags: [],

      allowComments: DEFAULT_ALLOW_COMMENTS,
      confirmationEmail: false,
      reminder: false,
      commentVisibilityLimited: this.props.commentVisibilityLimited,
      hasConvenors: false,
      showConvenors: false,
      convenors: [],
      userSearch: '',

      hasLink: false,
      buttonText: '',
      buttonLink: '',

      emailRequestBooking: '',

      hasRegistration: true,
      hasBookings: false,
      bookingData: {
        type: 'External',
      },

      upcomingReps: [],
      pastReps: [],
      now: moment.utc(),

      showPast: false,

      ..._.cloneDeep(this.initialAttendance),

      shouldNotify: false,
      selectedOption: 'audience',
      timeslotScrollOffset: 0,
      pageScrollOffset: 0,
      visibleTimeslotStart: 0,
      validLinkedContent: true,

      sendAlertOpen: false,

      isAudienceValid: true,
      audienceType: '',
      audienceTypeSelection: [],

      selectedMerchant: null,
      onchargeFees: false,

      recurrenceInfo: { IsRecurring: false, Time: '12:00' },
    };

    this.state = { ...this.initialState };
  }

  UNSAFE_componentWillMount() {
    this.onNewProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.onNewProps(nextProps);
  }

  componentDidMount() {
    if (this.props.events.length === 0 && !this.state.isEveryday) {
      this.loadEvents();
    }
    if (_.isEmpty(this.state.eventRepetitions)) {
      this.addRepetition();
    }
    this.getUsers();

    const params = getUrlParams();
    if (params.action && params.action === 'template') {
      this.setState({
        isTemplate: true,
      });
    }
    if (params.action && params.action === 'global') {
      this.setState({
        isGlobalEvent: true,
      });
    }
    if (params.sourceId) {
      let source = null;
      if (params.type === 'template') {
        source = _.find(this.props.templates, (e) => {
          return e.RowId === params.sourceId;
        });
      } else if (params.type === 'global') {
        source = _.find(this.props.globals, (e) => {
          return e.RowId === params.sourceId;
        });
      }
      if (source) {
        this.setState({
          sourceId: source.RowId,
        });
        this.parseEvent(source, params.sourceId);
      }
    }

    this.getLocationTags();

    // TEST
    // setTimeout(() => {
    //   // Select time slot
    //   this.selectRepetition({
    //     Id: 0,
    //     Date: '2021-05-22',
    //     DateText: '22/05/2021',
    //     AllDay: false,
    //     Time: '12:00',
    //     Location: '',
    //     TicketCount: '10',
    //   });
    //   setTimeout(() => {
    //     // Select Refund
    //     this.onSelectRefund({
    //       HasGuests: true,
    //       UnixTimestamp: 1621569093027,
    //       displayName: 'Admin',
    //       profilePic: null,
    //       id: '66a89251-22d1-4dd6-a357-9e91b343a324',
    //       Count: 4,
    //       Timestamp: '2021-05-21T03:51:32.968Z',
    //       Notes: null,
    //     });
    //   }, 5000);
    // }, 3000);

    this.props.addRecentlyCreated('events');
  }

  onNewProps(props) {
    const eventId = safeReadParams(props, 'eventId');
    const event = _.find(this.state.isEveryday ? props.facilities : props.events, (ev) => {
      return ev != null && ev.RowId === eventId;
    });

    if (event != null) this.parseEvent(event);
  }

  getLocationTags() {
    eventActions.getLocationTags(this.props.auth.site).then((res) => {
      this.setState({
        availableLocationTags: _.sortBy(res.data, (t) => t.Tag).map((t) => {
          return t.Tag;
        }),
      });
    });
  }

  getUsers() {
    userActions.fetchUsers(this.props.auth.site).then((res) => {
      if (res.data != null && !_.isEmpty(res.data.results.Items) && res.data.results.Items[0].site === this.props.auth.site) {
        this.props.usersLoaded(res.data.results.Items);
      }
    });
  }

  getEventReps = () => {
    if (this.state.loadingReps) {
      return;
    }
    this.setState({ loadingReps: true }, async () => {
      try {
        const { data } = await eventActions.getEventReps(this.props.auth.site, this.state.eventIdWithoutSite);
        const eventAttendees = data.map((rep) => {
          return {
            RepId: rep.RepId,
            Attendees: _.orderBy(_.values(rep.Attendees), 'displayName'),
            Waitlist: _.orderBy(_.values(rep.Waitlist), 'UnixTimestamp'),
          };
        });
        this.setState({ eventAttendees, loadingReps: false });
        this.updateRepAttendance(data);
      } catch (error) {
        this.setState({ loadingReps: false });
        console.log('getEventReps error', error);
      }
    });
  };

  // used to fix inconsistencies in attendance numbers vs tickets purchased
  checkFixAttendance = () => {
    const { auth } = this.props;
    const { eventIdWithoutSite, selectedTimeSlot, repPayments, eventAttendees, loadingReps, loadingPayments } = this.state;
    if (loadingReps || loadingPayments) {
      setTimeout(() => {
        this.checkFixAttendance();
      }, 1000);
      return;
    }
    const rep = eventAttendees.find((rep) => rep.RepId === selectedTimeSlot);

    const inconsistencies = eventPayments.getAttendanceInconsistencies((rep && rep.Attendees) || [], repPayments[selectedTimeSlot] || []);
    const promises = [];
    inconsistencies.forEach((user) => {
      promises.push(
        eventActions.registerForEvent(
          auth.site,
          user.userId,
          eventIdWithoutSite,
          selectedTimeSlot,
          user.ticketCount,
          user.originalAttendance ? user.originalAttendance.Notes : '',
        ),
      );
    });
    if (!_.isEmpty(promises)) {
      Promise.all(promises).then((res) => {
        this.getEventReps();
        this.getPayments();
      });
    }
  };

  getPayments = () => {
    const { auth, eventTicketDetails } = this.props;
    const { eventIdWithoutSite, selectedTimeSlot, repPayments, selectedMerchant } = this.state;
    if (!this.props.payment.enabled || !this.props.payment.hasValidCategories(eventTicketDetails, selectedMerchant)) return;

    this.setState({ loadingPayments: true }, async () => {
      try {
        const { data } = await paymentActions.getEventSlotTransactions(auth.site, eventIdWithoutSite, selectedTimeSlot);
        const newRepPayments = _.cloneDeep(repPayments);
        newRepPayments[selectedTimeSlot] = data;
        this.setState({ loadingPayments: false, repPayments: newRepPayments }, () => {
          // If there's a attendee to refund, refresh the transactions list
          const { attendeeToRefund } = this.state;
          if (attendeeToRefund) {
            const refundTransactions = this.getUserPayments(attendeeToRefund.id);
            this.setState({ refundTransactions });
          }
          this.checkFixAttendance(selectedTimeSlot);
        });
      } catch (error) {
        this.setState({ loadingPayments: false });
        console.log('getPayments', error);
      }
    });
  };

  getUserTransactions = (userId) => {
    const { repPayments, selectedTimeSlot } = this.state;

    const transactions = repPayments[selectedTimeSlot] || [];
    return transactions.filter((p) => p.UserId === userId);
  };

  getUserTransaction = (userId) => {
    const transactions = this.getUserTransactions(userId);
    return this.props.payment.combineTransactions(transactions);
  };

  getUserPayments = (userId) => {
    const transactions = this.getUserTransactions(userId);
    return this.props.payment.consolidateTransactions(transactions);
  };

  getSpotsAvailable = () => {
    const { selectedTimeSlot, selectedAttendee } = this.state;
    if (!selectedTimeSlot || !selectedAttendee) return;

    this.setState({ loadingSpots: true }, async () => {
      try {
        let spotsAvailable = null;
        const repsRes = await eventActions.getEventReps(this.props.auth.site, this.state.eventIdWithoutSite);
        if (repsRes && repsRes.data) {
          const match = _.filter(repsRes.data, (rep) => rep.RepId === selectedTimeSlot);
          if (match && match.length > 0) {
            const rep = match[0];
            // Check already in attendance
            const alreadyInAttendance = rep.Attendees && rep.Attendees[selectedAttendee.Id];
            if (alreadyInAttendance) {
              spotsAvailable = 0;
            } else {
              // Check available spots if limit set
              if ((rep.limit || 0) > 0) {
                spotsAvailable = rep.limit - (rep.Attendees ? _.keys(rep.Attendees).length : 0);
              }
            }
          }
        }
        this.setState({ loadingSpots: false, spotsAvailable });
      } catch (error) {
        this.setState({ loadingSpots: false });
        console.log('getSpotsAvailable error', error);
      }
    });
  };

  selectOption = (o) => {
    this.setState({
      selectedOption: o,
    });
    if (o === 'tags') {
      this.getLocationTags();
    }
  };

  isConvenor() {
    if (!_.isEmpty(this.props.managedEvents)) {
      return _.some(this.props.managedEvents, (ev) => {
        return ev.EventRowId === this.state.eventId;
      });
    }
    return false;
  }

  isOnlyConvenor() {
    if (validateAccess(this.props.auth.site, 'events', this.props.auth)) {
      return false;
    }
    return this.isConvenor();
  }

  canManageEvent() {
    if (!_.isEmpty(this.props.managedEvents)) {
      return _.some(this.props.managedEvents, (ev) => {
        return ev.EventRowId === this.state.eventId;
      });
    }
    return validateAccess(this.props.auth.site, 'events', this.props.auth);
  }

  canSubmitEvent() {
    return !this.state.submitting && validateAccess(this.props.auth.site, 'eventSubmit', this.props.auth) && this.state.eventId == null;
  }

  canViewPayment = () => {
    if (!this.props.siteLevel.hasPayments) {
      return false;
    }
    const { isEveryday } = this.state;
    const { merchants, auth } = this.props;
    return !isEveryday && (_.isEmpty(merchants) || validateAccess(auth.site, 'eventPayment', auth));
  };

  isPaymentReadOnly = () => {
    const { merchants, auth } = this.props;
    // Inputs disabled OR (Account connected AND (payment not granted or existing event))
    return (
      this.inputsDisabled() ||
      (!_.isEmpty(merchants) && (!validateAccess(auth.site, 'eventPayment', auth) || !_.isEmpty(this.state.eventId)))
    );
  };

  isAttendeeValid = () => {
    const { savingAttendee, selectedTimeSlot, selectedAttendee, attendanceCount } = this.state;
    return !savingAttendee && !_.isNil(selectedTimeSlot) && selectedAttendee && selectedAttendee.Id && attendanceCount > 0;
  };

  inputsDisabled() {
    if (this.state.isSourceGlobal) {
      return true;
    }
    if (this.canManageEvent()) {
      return false;
    }
    if (this.canSubmitEvent()) {
      return false;
    }
    return true;
  }

  getActualTickets = (tickets = null) => {
    const { selectedTransaction, ticketsEntered } = this.state;
    const { payment } = this.props;

    tickets = _.isNull(tickets) ? ticketsEntered : tickets;
    const actual = selectedTransaction ? payment.combineTickets([payment.getPayCategories(selectedTransaction), tickets], true) : tickets;
    return actual;
  };

  getConsolidatedSpots = (userId, tickets) => {
    const { payment } = this.props;

    const userTransaction = this.getUserTransaction(userId);
    const actualTickets = this.getActualTickets(tickets);
    const combinedTickets = payment.combineTickets([payment.getPayCategories(userTransaction), actualTickets]);
    return payment.getTotalQuantity(combinedTickets);
  };

  hasTicketsChanged = () => {
    return this.getActualTickets().some((t) => t.quantity !== 0);
  };

  onChangeTicketCategory = (index, value) => {
    const { eventTicketDetails } = this.state;
    const newDetails = _.cloneDeep(eventTicketDetails);
    newDetails.categories[index].category = value;
    this.setState({ eventTicketDetails: newDetails });
  };

  onChangeTicketPrice = (index, value) => {
    const { eventTicketDetails } = this.state;
    const newDetails = _.cloneDeep(eventTicketDetails);
    newDetails.categories[index].price = value;
    this.setState({ eventTicketDetails: newDetails });
  };

  onAddPriceCategory = () => {
    const { eventTicketDetails } = this.state;
    const newDetails = _.cloneDeep(eventTicketDetails);
    newDetails.categories.push({ category: '', price: '' });
    this.setState({ eventTicketDetails: newDetails });
  };

  onRemovePriceCategory = (index) => {
    const { eventTicketDetails } = this.state;
    const newDetails = _.cloneDeep(eventTicketDetails);
    newDetails.categories.splice(index, 1);
    this.setState({ eventTicketDetails: newDetails });
  };

  isReadyToOpenCSV = () => {
    if (_.isUndefined(this.state.selectedTimeSlot)) {
      return false;
    }
    if (this.state.loadingPayments) {
      return false;
    }
    if (_.isEmpty(this.state.eventAttendees)) {
      return false;
    }
    const rep = this.state.eventAttendees.find((rep) => rep.RepId === this.state.selectedTimeSlot);
    if (!rep || !rep.Attendees) {
      return false;
    }
    return true;
  };

  onOpenCSV = () => {
    if (!this.isReadyToOpenCSV()) {
      return;
    }
    this.setState({
      isCSVOpen: true,
    });
  };

  onCloseCSV = () => {
    this.setState({
      isCSVOpen: false,
    });
  };

  getExportTitle = () => {
    if (!this.isReadyToOpenCSV()) {
      return '';
    }
    const repInfo = this.getEventRepetitions().find((rep) => rep.Id === this.state.selectedTimeSlot);
    return `${onlyAlphanumeric(this.state.eventTitle).substr(0, 20)}_${moment(repInfo.Date, 'YYYY-MM-DD').local().format('DD-MM-YYYY')}`;
  };

  getExportSource = () => {
    if (!this.isReadyToOpenCSV()) {
      return [];
    }
    const rep = this.state.eventAttendees.find((rep) => rep.RepId === this.state.selectedTimeSlot);
    const repInfo = this.getEventRepetitions().find((rep) => rep.Id === this.state.selectedTimeSlot);
    const attendees = rep.Attendees;

    const result = _.filter(attendees, (a) => !a.isGuest).map((a) => {
      const transaction = this.getUserTransaction(a.id);
      const user = this.props.users.find((u) => u.Id === a.id);

      let tickets = '';
      let transactionId = '';
      let amount = 0;
      let fee = 0;
      if (transaction) {
        if (transaction.Amount) {
          amount = transaction.Amount;
        }
        if (transaction.TransactionId) {
          transactionId = transaction.TransactionId;
        }
        if (transaction.Fee && transaction.Fee.amount) {
          fee = transaction.Fee.amount;
        }
        let categories = [];
        if (transaction.PaymentInfo && !_.isEmpty(transaction.PaymentInfo.categories)) {
          categories = transaction.PaymentInfo.categories;
        } else if (transaction.Item && !_.isEmpty(transaction.Item.categories)) {
          categories = transaction.Item.categories;
        }
        tickets = categories
          .map((c) => {
            return `${c.quantity}x ${c.category}`;
          })
          .join(', ');
      }
      let unit = '';
      if (user) {
        unit = user.unit;
      }

      return {
        Title: this.state.eventTitle,
        Date: moment(repInfo.Date, 'YYYY-MM-DD').local().format('DD/MM/YYYY'),
        Time: this.getRepTimeText(repInfo),
        displayName: a.displayName,
        Unit: unit,
        id: a.id,
        Timestamp: moment(a.Timestamp).local().format(),
        Count: a.Count || 1,
        Notes: a.Notes,
        Tickets: tickets,
        TransactionId: transactionId,
        Amount: amount,
        Fee: fee,
      };
    });
    return result;
  };

  getExportColumns = () => {
    const { repPayments, selectedTimeSlot } = this.state;
    if (_.isEmpty(repPayments[selectedTimeSlot])) {
      return this.exportColumns;
    }
    return [...this.exportColumns, ...this.paidExportColumns];
  };

  onCloseAttendance = () => {
    this.setState({ ..._.cloneDeep(this.initialAttendance) });
  };

  onAddAttendees = () => {
    this.setState({ addingAttendees: true });
  };

  onCloseAddAttendees = () => {
    this.setState({ ..._.cloneDeep(this.initialAddAttendee) }, this.getEventReps);
  };

  onRemoveUser = (attendee, callBack = null, refundAll = false) => {
    const {
      cancellingAttendee,
      cancellingAttendeeId,
      eventIdWithoutSite,
      selectedTimeSlot,
      ticketsEntered,
      selectedTransaction,
      onchargeFees,
    } = this.state;
    const { auth } = this.props;

    if ((cancellingAttendee && cancellingAttendeeId === attendee.id) || _.isNil(selectedTimeSlot)) return;

    let confirmMessage = '';
    let tickets = [];
    let totalRefund = 0;
    let fee = 0;
    let transactionId = null;
    if (refundAll) {
      confirmMessage = 'Are you sure you want to refund all tickets?';
    } else if (selectedTransaction) {
      transactionId = selectedTransaction.TransactionId;
      tickets = this.getActualTickets(ticketsEntered);
      totalRefund = Math.abs(this.props.payment.getTotalPrice(tickets));
      fee = onchargeFees ? 0 : this.props.payment.getFee(selectedTransaction.Fee, totalRefund);
      confirmMessage = `Are you sure you want to refund ${this.props.payment.formatCurrency(totalRefund - fee)}?`;
    } else {
      confirmMessage = `Are you sure you want to cancel the attendance for ${attendee.displayName}?`;
    }
    if (!window.confirm(confirmMessage)) return;

    this.setState({ cancellingAttendee: true, cancellingAttendeeId: attendee.id }, async () => {
      try {
        let newCount = 0;
        if (refundAll || selectedTransaction) {
          await paymentActions.refund(null, auth.site, attendee.id, eventIdWithoutSite, selectedTimeSlot, tickets, transactionId);
          newCount = refundAll ? 0 : this.getConsolidatedSpots(attendee.id, ticketsEntered);
        }

        let res = null;
        if (newCount === 0) {
          res = await eventActions.removeEventAttendee(auth.site, attendee.id, eventIdWithoutSite, selectedTimeSlot);
        } else {
          res = await eventActions.registerForEvent(auth.site, attendee.id, eventIdWithoutSite, selectedTimeSlot, newCount, attendee.Notes);
        }
        if (res.data.success) {
          this.getEventReps();
          this.getPayments();
          if (callBack) callBack();
        }
      } catch (error) {
        console.log('onRemoveUser', error);
      } finally {
        this.setState({ cancellingAttendee: false, cancellingAttendeeId: null });
      }
    });
  };

  onSelectAttendee = (attendee) => {
    const { selectedAttendee } = this.state;
    const newSelectedAttendee = selectedAttendee && selectedAttendee.Id === attendee.Id ? null : attendee;
    this.setState({ selectedAttendee: newSelectedAttendee, attendanceCount: 0, attendanceNotes: '' }, this.getSpotsAvailable);
  };

  onChangeAttendanceCount = (attendanceCount) => this.setState({ attendanceCount });

  onSaveAttendance = () => {
    if (!this.isAttendeeValid()) return;

    const { auth } = this.props;
    const { selectedTimeSlot, selectedAttendee, attendanceCount, attendanceNotes, eventIdWithoutSite } = this.state;

    this.setState({ savingAttendee: true }, async () => {
      try {
        const spotsAvailable = await this.getSpotsAvailable();
        if (!_.isNumber(spotsAvailable) && spotsAvailable < attendanceCount) {
          const availPlural = spotsAvailable > 1;
          const registerError = `You have requested ${attendanceCount} ticket${getPluralS(attendanceCount)} but there ${
            availPlural ? 'are' : 'is'
          } only ${spotsAvailable} spot${getPluralS(spotsAvailable)} available.`;

          window.alert(registerError);
          return;
        }

        await eventActions.registerForEvent(
          auth.site,
          selectedAttendee.Id,
          eventIdWithoutSite,
          selectedTimeSlot,
          attendanceCount,
          attendanceNotes,
        );
        this.setState({ savingAttendee: false }, this.onCloseAddAttendees);
      } catch (error) {
        this.setState({ savingAttendee: false }, () => {
          window.alert('There was a problem registering');
        });
        console.log('onSaveAttendance', error);
      }
    });
  };

  onSelectRefund = (attendeeToRefund) => {
    const refundTransactions = this.getUserPayments(attendeeToRefund.id);

    this.setState({ attendeeToRefund, refundTransactions }, () => {
      // If there's only one transaction to refund from, go to that transaction directly
      if (refundTransactions.length === 1) this.onSelectRefundTransaction(refundTransactions[0]);
    });
  };

  onSelectRefundTransaction = (selectedTransaction) => {
    const ticketsEntered = selectedTransaction ? _.cloneDeep(this.props.payment.getPayCategories(selectedTransaction)) : [];
    this.setState({ selectedTransaction, ticketsEntered });
  };

  onCloseSelectTransaction = () => {
    this.setState({ ..._.cloneDeep(this.initialRefund) });
  };

  onCloseRefund = () => {
    this.setState({ selectedTransaction: null }, () => {
      // If there's one or no transactions, close transactions list
      const { refundTransactions } = this.state;
      if (refundTransactions.length <= 1) this.onCloseSelectTransaction();
    });
  };

  onChangeRefundCount = (category, price, quantity) => {
    const { ticketsEntered } = this.state;
    const newTickets = [...ticketsEntered];
    const ticket = newTickets.find((t) => t.category === category);
    if (ticket) {
      ticket.price = price;
      ticket.quantity = quantity;
    } else {
      newTickets.push({ category, price, quantity });
    }
    this.setState({ ticketsEntered: newTickets });
  };

  toggleTextFormat(isOpen) {
    this.setState({
      textFormatOpen: isOpen,
    });
  }

  parseEvent = (event) => {
    const merchantToUse = (() => {
      if (event.Tickets.merchant) return event.Tickets.merchant;
      if (_.isEmpty(this.props.merchants)) return null;
      return this.props.merchants[0].id;
    })();
    this.selectMerchant(merchantToUse);
    const eventTicketDetails = this.props.payment.parsePaymentInfo(event.Tickets, merchantToUse);
    const onchargeFees = event.Tickets.onchargeFees || false;
    let newState = {
      eventTitle: event.Title,
      eventDescription: event.Description,
      //eventImage: event.Image,
      eventLocation: event.Location,
      eventLink: event.Link,
      eventTicketLink: event.TicketLink,
      eventTicketDetails,
      onchargeFees,
      eventGPS: '',
      eventFF: event.FamilyFriendly,
      eventShortId: event.Id,
      eventSpotify: event.Spotify ? event.Spotify : '',
      eventAttachments: _.isEmpty(event.Attachments) ? [] : event.Attachments,
      area: _.isEmpty(event.Area) ? 'Global' : event.Area,
      eventRepetitions: [],
      organiser: event.Organiser ? event.Organiser : '',
      phone: event.Phone ? event.Phone : '',
      isEveryday: Boolean(event.IsEveryday),
      isFeatured: !Boolean(event.IsEveryday) && Boolean(event.IsFeatured),
      eventIdWithoutSite: event.Id,
      isTvMode: Boolean(event.TVMode),
      allowComments: Boolean(event.AllowComments),
      confirmationEmail: Boolean(event.ConfirmationEmail),
      reminder: Boolean(event.Reminder),
      commentVisibilityLimited: Boolean(event.CommentVisibilityLimited),
      hasRegistration: !Boolean(event.HideRegistration),
      locationTags: event.LocationTags || [],
      audienceType: event.AudienceType || '',
      audienceTypeSelection: event.AudienceTypeSelection || [],
      recurrenceInfo: event.RecurrenceInfo || this.state.recurrenceInfo,
      recurrenceMaxId: event.RecurrenceMaxId,
      recurrenceChanged: event.RecurrenceChanged,
    };

    if (isContentSource(this.props.auth.site)) {
      if (event.IsGlobal) {
        newState.isGlobalEvent = true;
      }
      if ((event.IsTemplate = 'X')) {
        newState.isTemplate = true;
      }
    }

    if (!_.isUndefined(event.HasBookingOptions) && event.HasBookingOptions) {
      newState.hasBookings = true;
      newState.bookingData = event.BookingData;

      // if external booking type, set booking button/link fields
      if (
        event.BookingData.type === 'External' &&
        !_.isUndefined(event.BookingData.ButtonText) &&
        !_.isUndefined(event.BookingData.ButtonLink)
      ) {
        newState.buttonLink = event.BookingData.ButtonLink;
      }
      if (event.BookingData.type === 'EmailRequest') {
        newState.emailRequestBooking = event.BookingData.RequestEmail;
      }
      if (event.BookingData.type === 'External' && !_.isUndefined(event.BookingData.ButtonLink)) {
        newState.buttonLink = event.BookingData.ButtonLink;
      }
      if (event.BookingData.type === 'EmailRequest') {
        newState.emailRequestBooking = event.BookingData.RequestEmail;
      }

      newState.requestButtonText = event.BookingData.ButtonText || '';
      newState.confirmationTitle = event.BookingData.ConfirmationTitle || '';
      newState.confirmationText = event.BookingData.ConfirmationText || '';
    }

    if (!_.isEmpty(event.Category)) {
      const tags = this.selectCategory(event.Category);
      if (tags && !_.isEmpty(event.Tags)) {
        _.values(tags).forEach((tag) => {
          tag.Active = event.Tags.indexOf(tag.Key) > -1;
        });
      }
    }

    if (event.RepeatedTimes) {
      let maxId = 0;
      event.RepeatedTimes.forEach((repetition) => {
        newState.eventRepetitions.push({
          Id: repetition.Id != null ? repetition.Id : maxId++,
          RecurId: repetition.RecurId,
          Date: moment.utc(repetition.Time).local().format('YYYY-MM-DD'),
          DateText: moment.utc(repetition.Time).local().format('DD/MM/YYYY'),
          AllDay: repetition.AllDay,
          Time: moment.utc(repetition.Time).local().format('HH:mm'),
          EndTime: repetition.EndTime ? moment.utc(repetition.EndTime).local().format('HH:mm') : undefined,
          Location: repetition.Location ? repetition.Location : '',
          TicketCount: '' + repetition.TicketCount,
          AttendanceCount: repetition.AttendanceCount,
        });
      });
    }

    if (event.GPS != null) {
      newState.eventGPS = `${event.GPS['Lat']}, ${event.GPS['Lng']}`;
    }

    newState.upcomingReps = [];
    newState.pastReps = [];
    const today = moment().startOf('day');

    newState.eventRepetitions.forEach((rep) => {
      const isFuture = today.isSameOrBefore(moment.utc(rep.Date).local());
      if (isFuture) {
        newState.upcomingReps.push(rep);
      } else {
        newState.pastReps.push(rep);
      }
    });

    if (!_.isEmpty(event.Convenors)) {
      newState.hasConvenors = true;
      newState.convenors = event.Convenors;
    }

    newState.isSourceGlobal = event.IsGlobal;
    newState.isSourceTemplate = event.IsTemplate;
    newState.globalAllowDescriptionEdit = event.GlobalAllowDescriptionEdit;
    newState.globalAllowTimeEdit = event.GlobalAllowTimeEdit;

    newState = { ...newState };

    this.setState(newState);

    this.checkSetImage(event.Image);
    this.checkSetLinkedContent(event.LinkedContent);

    this.getEventReps();
  };

  checkSetImage(url) {
    if (this.refs.imageInput) {
      this.setState({
        image: url,
      });
      this.refs.imageInput.getWrappedInstance().setValue(url);
    } else {
      setTimeout(() => {
        this.checkSetImage(url);
      }, 100);
    }
  }

  checkSetLinkedContent(linkedContent) {
    if (this.linkedContentSelector) {
      this.linkedContentSelector.getWrappedInstance().setData(linkedContent);
    } else {
      setTimeout(() => {
        this.checkSetLinkedContent(linkedContent);
      }, 100);
    }
  }

  loadEvents() {
    this.setState({
      loading: true,
    });

    eventActions
      .getEvents(this.props.auth.site, 0, true)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === this.props.auth.site) {
          this.props.eventsLoaded(res.data);
        }
      })
      .catch((res) => {
        this.setState({ loading: false });
        alert('Something went wrong with the request. Please try again.');
      });
  }

  getSelectedCategory() {
    if (categories[this.state.eventTag]) {
      return categories[this.state.eventTag];
    }
    return {
      Title: 'Category',
    };
  }

  getSelectedBookingType() {
    if (bookingTypes[this.state.bookingData.type]) {
      return bookingTypes[this.state.bookingData.type];
    }
    return {
      Title: 'Select',
    };
  }

  getMerchantOptions = () => {
    return (this.props.merchants || []).map((m) => {
      return {
        ...m,
        Key: m.id,
        Title: m.nickname ? `${m.nickname} (${m.id})` : m.id,
      };
    });
  };

  getSelectedMerchant() {
    const selection = _.find(this.props.merchants, (m) => {
      return m.id === this.state.selectedMerchant;
    });
    if (!selection) {
      return {
        Title: 'Select',
      };
    }
    return {
      Title: selection.nickname ? `${selection.nickname} (${selection.id})` : selection.id,
      Id: selection.id,
    };
  }

  selectMerchant = (m) => {
    this.setState({
      selectedMerchant: m,
    });
    this.getFeeStructure(m);
  };

  getFeeStructure = (accountId) => {
    if (!accountId) {
      this.setState({ feeStructure: null });
      return;
    }
    paymentActions.getFeeStructure(this.props.auth.site, accountId).then((res) => {
      this.setState({
        feeStructure: res.data,
      });
    });
  };

  isEmpty(text) {
    return text === '';
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  handleRepetitionChange(Id, column, value) {
    var stateChange = {
      eventRepetitions: this.state.eventRepetitions,
    };
    const index = _.findIndex(stateChange.eventRepetitions, (rep) => {
      return rep.Id === Id;
    });
    stateChange.eventRepetitions[index][column] = value;
    this.setState(stateChange);
  }

  handleRepetitionDateTextChange(id, value) {
    var stateChange = {
      eventRepetitions: this.state.eventRepetitions,
    };
    const index = _.findIndex(stateChange.eventRepetitions, (rep) => {
      return rep.Id === id;
    });
    stateChange.eventRepetitions[index].DateText = value;
    const m = moment(value, 'DD/MM/YYYY');
    if (m.isValid() && m.year() > 1970) {
      stateChange.eventRepetitions[index].Date = m.format('YYYY-MM-DD');
    }
    this.setState(stateChange);
  }

  handleRepetitionDateChange(id, date) {
    var stateChange = {
      eventRepetitions: this.state.eventRepetitions,
    };
    const index = _.findIndex(stateChange.eventRepetitions, (rep) => {
      return rep.Id === id;
    });
    stateChange.eventRepetitions[index].Date = date;
    stateChange.eventRepetitions[index].DateText = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    stateChange.eventRepetitions[index].ShowDatepicker = false;
    this.setState(stateChange);
  }

  handleRepetitionAllDayChange(id, isAllDay) {
    var stateChange = {
      eventRepetitions: this.state.eventRepetitions,
    };
    const index = _.findIndex(stateChange.eventRepetitions, (rep) => {
      return rep.Id === id;
    });
    stateChange.eventRepetitions[index].AllDay = isAllDay;
    // All day events default to 12:00 AM
    stateChange.eventRepetitions[index].Time = isAllDay ? '00:00' : '12:00';
    this.setState(stateChange);
  }

  handleRepetitionTimeChange(id, key, time) {
    var stateChange = {
      eventRepetitions: [...this.state.eventRepetitions],
    };
    const index = _.findIndex(stateChange.eventRepetitions, (rep) => {
      return rep.Id === id;
    });
    stateChange.eventRepetitions[index][key] = time;
    this.setState(stateChange);
  }

  handleAttachmentChange(index, column, event) {
    var stateChange = {
      eventAttachments: this.state.eventAttachments,
    };
    stateChange.eventAttachments[index][column] = event.target.value;
    this.setState(stateChange);
  }

  handleCheckChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.checked;
    this.setState(stateChange);
  }

  handleRecInfoChange = (name, value) => {
    const recurrenceInfo = { ...this.state.recurrenceInfo };
    if (name === 'TicketCount') {
      recurrenceInfo[name] = parseInt(value, 10);
    } else {
      recurrenceInfo[name] = value;
    }
    // All day events default to 12:00 AM
    if (name === 'AllDay') {
      recurrenceInfo.Time = value ? '00:00' : '12:00';
      recurrenceInfo.EndTime = '';
    }
    this.setState({ recurrenceInfo }, () => {
      // console.log('handleRecInfoChange', this.state.recurrenceInfo);
    });
  };

  onClickConvenors() {
    if (this.state.showConvenors) {
      this.setState({ showConvenors: false });
    } else {
      setTimeout(() => {
        if (this.state.hasConvenors && !this.state.showConvenors) {
          this.setState({
            showConvenors: true,
            selectedTimeSlot: null,
          });
        }
      }, 50);
    }
  }

  handleSearchChange(event) {
    if (this.state.searchUserLoading) {
      return;
    }
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  onImageUpdated = (url) => {
    this.setState({
      image: url,
    });
  };

  addConvenor(user) {
    if (
      _.some(this.state.convenors, (u) => {
        return u.userId === user.Id;
      })
    ) {
      return this.removeConvenor({ userId: user.Id });
    }
    const newConvenors = _.clone(this.state.convenors);
    newConvenors.push({
      profilePic: user.profilePic,
      displayName: user.displayName,
      userId: user.Id,
    });
    this.setState({
      convenors: newConvenors,
    });
  }

  removeConvenor(user) {
    this.setState({
      convenors: _.filter(this.state.convenors, (u) => {
        return u.userId !== user.userId;
      }),
    });
  }

  validateRep(rep) {
    if (rep.Removed) {
      return true;
    }
    if (!this.validateRepetitionTime(rep)) {
      return false;
    }
    if (!this.validateRepetitionDate(rep)) {
      return false;
    }
    return true;
  }

  validateReps() {
    const { isEveryday, recurrenceInfo, eventRepetitions } = this.state;

    if (isEveryday) return true;

    if (recurrenceInfo && recurrenceInfo.IsRecurring) {
      if (!recurrenceInfo.RecurrenceRule) return false;
      if (!this.validateRepetitionTime(recurrenceInfo)) return false;
      return true;
    }

    return !_.some(eventRepetitions, (rep) => {
      return !this.validateRep(rep);
    });
  }

  validateGPS() {
    if (this.isEmpty(this.state.eventGPS)) {
      return true;
    }
    const split = this.state.eventGPS.split(',');
    if (split.length !== 2) {
      return false;
    }
    if (isNaN(parseFloat(split[0])) || isNaN(parseFloat(split[1]))) {
      return false;
    }
    return true;
  }

  validateLoading() {
    if (this.state.submitting) {
      return false;
    }
    return true;
  }

  validateImage() {
    return this.state.image && this.state.image.match(/\.(jpeg|jpg|gif|png|ashx)/) != null;
  }

  isValidLink() {
    /* const regexp = /^((https|http):\/\/)(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        if (regexp.test(this.state.buttonLink)) {
            return true;
        }
        else {
            return false;
        } */
    const regexp = /(http:\/\/|https:\/\/)/;
    if (regexp.test(this.state.buttonLink)) {
      return true;
    }
    return false;
  }

  validateEmailRequestField() {
    if (
      this.state.hasBookings &&
      this.state.bookingData.type === 'EmailRequest' &&
      (this.isEmpty(this.state.emailRequestBooking) || !isEmail(this.state.emailRequestBooking))
    ) {
      return false;
    }
    return true;
  }

  validateButtonLink() {
    if (
      this.state.hasBookings &&
      this.state.bookingData.type === 'External' &&
      (this.isEmpty(this.state.requestButtonText) || this.isEmpty(this.state.buttonLink))
    ) {
      return false;
    }
    return true;
  }

  validateCompulsoryText() {
    if (this.isEmpty(this.state.eventTitle)) {
      return false;
    }
    return true;
  }

  validateOrganiser() {
    if (!_.isEmpty(this.state.phone)) {
      return !_.isEmpty(this.state.organiser);
    }
    return true;
  }

  validateCategory() {
    if (categories[this.state.eventTag]) {
      return true;
    }
    return false;
  }

  validateForm() {
    if (!this.validateReps()) return false;
    if (!this.validateCompulsoryText()) return false;
    if (!this.isPaymentReadOnly() && !this.props.payment.hasValidCategories(this.state.eventTicketDetails, this.state.selectedMerchant))
      return false;
    if (!this.validateImage()) return false;
    if (!this.validateLoading()) return false;
    if (!this.validateGPS()) return false;
    if (!this.validateOrganiser()) return false;
    if (!this.validateButtonLink()) return false;
    if (!this.validateEmailRequestField()) return false;
    if (!this.state.validLinkedContent) return false;
    if (!this.state.isAudienceValid) return false;
    return true;
  }

  validateRepetitionDate(repetition) {
    if (!_.isUndefined(repetition.Date)) {
      const date = new Date(repetition.Date.trim());
      return repetition.Date.trim() !== '' && !isNaN(date);
    }
    return false;
  }

  validateRepetitionTime(repetition) {
    if (!_.isUndefined(repetition.Time)) {
      if (_.isEmpty(repetition.EndTime)) {
        return repetition.Time.trim() !== '';
      }
      const startTime = getTimepickerTime(repetition.Time);
      const endTime = getTimepickerTime(repetition.EndTime);
      if (startTime.hours > endTime.hours) {
        return false;
      }
      if (startTime.hours < endTime.hours) {
        return true;
      }
      return startTime.minutes <= endTime.minutes;
    }
    return false;
  }

  setRepetitions(input) {
    input.RepeatedTimes = [];

    if (input.IsEveryday) {
      input.RepeatedTimes = [
        {
          Id: 0,
          AllDay: false,
          Time: moment.utc().startOf('d'),
          Location: '',
          TicketCount: 0,
        },
      ];
    } else {
      this.state.eventRepetitions.forEach((repetition) => {
        if (!repetition.Removed) {
          let ticketCount = 0;
          if (
            !_.isEmpty(repetition.TicketCount) &&
            !isNaN(parseInt(repetition.TicketCount, 10)) &&
            parseInt(repetition.TicketCount, 10) > 0
          ) {
            ticketCount = parseInt(repetition.TicketCount, 10);
          }
          input.RepeatedTimes.push({
            Id: repetition.Id,
            RecurId: repetition.RecurId,
            AllDay: repetition.AllDay,
            Time: getDateFromTimeDatePickers(repetition.Date.trim(), repetition.Time.trim()),
            EndTime:
              repetition.EndTime && !repetition.AllDay
                ? getDateFromTimeDatePickers(repetition.Date.trim(), repetition.EndTime.trim())
                : undefined,
            Location: repetition.Location,
            TicketCount: ticketCount,
            AttendanceCount: repetition.AttendanceCount,
          });
        }
      });
      input.RepeatedTimes = _.sortBy(input.RepeatedTimes, (repetition) => {
        return moment(repetition.Time).valueOf();
      });
    }
  }

  setTags(input) {
    input.Tags = _.filter(_.values(this.state.selectableTags), (tag) => {
      return tag.Active;
    }).map((tag) => {
      return tag.Key;
    });
  }

  setGPS(input) {
    if (this.isEmpty(this.state.eventGPS)) {
      return;
    }
    const split = this.state.eventGPS.split(',');
    const gps = {
      Lat: parseFloat(split[0]),
      Lng: parseFloat(split[1]),
    };
    input.GPS = gps;
  }

  setAttachments(input) {
    input.Attachments = [];
    this.state.eventAttachments.forEach((attachment) => {
      if (!attachment.Removed && !_.isEmpty(attachment.Source)) {
        input.Attachments.push({
          Id: attachment.Id,
          Title: attachment.Title,
          Source: attachment.Source,
        });
      }
    });
  }

  compileJson = async () => {
    const tickets = this.props.payment.composePaymentInfo(
      _.isEmpty(this.props.merchants) ? null : this.state.selectedMerchant,
      this.state.eventTicketDetails.categories,
      this.state.onchargeFees,
    );
    const result = {
      Title: this.state.eventTitle,
      Image: this.refs.imageInput.getWrappedInstance().getValue(),
      Description: this.state.eventDescription,
      Location: this.state.eventLocation,
      Tickets: tickets,
      Category: eventsHaveTags ? this.state.eventTag : '',
      Date: null,
      Time: '',
      StartTime: null,
      EndTime: null,
      RepeatedTimes: null,
      AllDay: this.state.eventAllDay,
      Link: this.state.eventLink,
      FamilyFriendly: this.state.eventFF,
      TicketLink: this.state.eventTicketLink,
      Thumbnail: this.refs.imageInput.getWrappedInstance().getValue(),
      Area: this.state.area,
      Spotify: this.state.eventSpotify,
      Site: this.props.auth.site,
      IsEveryday: this.state.isEveryday,
      IsFeatured: this.state.isFeatured,
      Phone: this.state.phone,
      Organiser: this.state.organiser,
      HasBookingOptions: this.state.hasBookings,
      HideRegistration: !this.state.hasRegistration,
      BookingData: null,
      TVMode: this.state.isTvMode,
      AllowComments: _.includes(this.props.auth.hidden, 'eventComments') ? false : this.state.allowComments,
      ConfirmationEmail: this.state.confirmationEmail,
      Reminder: this.state.reminder,
      Notification: _.includes(this.props.auth.hidden, 'eventNotifications') ? false : this.state.shouldNotify,
      LinkedContent: this.linkedContentSelector.getWrappedInstance().getData(),
      AudienceType: this.state.audienceType,
      AudienceTypeSelection: this.state.audienceTypeSelection,
      RecurrenceInfo: this.state.recurrenceInfo,
      RecurrenceMaxId: this.state.recurrenceMaxId,
      RecurrenceChanged: this.state.recurrenceChanged,
    };

    if (result.AllowComments) {
      result.CommentVisibilityLimited = this.state.commentVisibilityLimited;
    }

    if (this.state.eventId != null) {
      result.RowId = this.state.eventId;
    }
    if (this.state.eventShortId != null) {
      result.Id = this.state.eventShortId;
    }

    if (result.HasBookingOptions) {
      result.BookingData = { type: this.state.bookingData.type };
      if (result.BookingData.type === 'External') {
        result.BookingData.ButtonLink = this.state.buttonLink;

        if (!this.isValidLink(this.state.buttonLink)) {
          result.BookingData.ButtonLink = 'http://' + this.state.buttonLink;
        }
      }
      if (result.BookingData.type === 'EmailRequest') {
        result.BookingData.RequestEmail = this.state.emailRequestBooking;
        if (!_.isEmpty(this.state.confirmationTitle) || !_.isEmpty(this.state.confirmationText)) {
          result.BookingData.ConfirmationTitle = this.state.confirmationTitle;
          result.BookingData.ConfirmationText = this.state.confirmationText;
        }
      }
      if (result.BookingData.type !== 'NoBooking') {
        if (!_.isEmpty(this.state.requestButtonText)) {
          result.BookingData.ButtonText = this.state.requestButtonText;
        }
      }
    }

    if (this.state.hasConvenors && !_.isEmpty(this.state.convenors)) {
      result.Convenors = this.state.convenors;
    }

    // if (this.state.hasLink) {
    //     result.ButtonText = this.state.buttonText;
    //     result.ButtonLink = this.state.buttonLink;

    //     if (!this.isValidLink(this.state.buttonLink)) {
    //         result.ButtonLink = 'http://' + this.state.buttonLink;
    //     }
    // }

    this.setRepetitions(result);
    this.setAttachments(result);
    if (eventsHaveTags) {
      this.setTags(result);
    }
    this.setGPS(result);

    result.LocationTags = this.state.locationTags;

    result.Thumbnail = getThumb300(result.Image);
    result.Image = get1400(result.Image);
    if (result.Category && result.Category.indexOf(',') !== -1) {
      result.Category = result.Category.split(',')[0];
    }

    if (this.state.sourceId) {
      result.IsGlobal = this.state.isSourceGlobal;
      result.IsTemplate = this.state.isSourceTemplate;
      result.GlobalAllowDescriptionEdit = this.state.globalAllowDescriptionEdit;
      result.GlobalAllowTimeEdit = this.state.globalAllowTimeEdit;
      result.SourceId = this.state.sourceId;
      result.GlobalStartTime = moment(result.RepeatedTimes[0].Time).valueOf();
    }
    if (this.state.isGlobalEvent) {
      result.IsGlobal = 'X';
      result.GlobalAllowDescriptionEdit = this.state.globalAllowDescriptionEdit;
      result.GlobalAllowTimeEdit = this.state.globalAllowTimeEdit;
      result.GlobalStartTime = moment(result.RepeatedTimes[0].Time).valueOf();
    }
    if (this.state.isTemplate) {
      result.IsTemplate = 'X';
    }

    return result;
  };

  handleSubmit = () => {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    this.setState({ submitting: true }, async () => {
      try {
        const data = await this.compileJson();
        // console.log('handleSubmit - after compileJson', data.Tickets);

        const res = await eventActions.addOrEditEvent(data);
        this.setState({ success: true, submitting: false });
        this.props.eventsUpdate(this.props.auth.site);
      } catch (error) {
        this.setState({ submitting: false });
        alert('Something went wrong with the request. Please try again.');
      }
    });
  };

  clearForm() {
    this.setState(this.initialState);
    setTimeout(() => {
      this.addRepetition();
    }, 50);
  }

  clearSuccess() {
    this.setState({
      success: false,
      submitting: false,
      showWarnings: false,
    });
  }

  updateLinkedContentValidation = (isValid) => {
    this.setState({
      validLinkedContent: isValid,
    });
  };

  selectBookingType(tag) {
    if (!bookingTypes[tag]) {
      return null;
    }
    const newBooking = this.state.bookingData;
    newBooking.type = tag;
    this.setState({
      bookingData: newBooking,
    });
  }

  selectCategory(tag) {
    if (!categories[tag]) {
      return null;
    }
    const tags = categories[tag].Sub;
    this.setState({
      eventTag: tag,
      selectableTags: tags,
    });
    return tags;
  }

  toggleTag(tag) {
    if (this.inputsDisabled()) {
      return;
    }
    const newTags = { ...this.state.selectableTags };
    newTags[tag].Active = !newTags[tag].Active;
    this.setState({
      selectableTags: newTags,
    });
  }

  addAttachment = (url) => {
    if (_.isEmpty(url)) {
      return;
    }
    const newReps = [...this.state.eventAttachments];
    const id = _.isEmpty(this.state.eventAttachments) ? 0 : _.maxBy(this.state.eventAttachments, 'Id').Id + 1;
    newReps.push({
      Id: id,
      Title: getFileName(url, true),
      Source: url,
      Uploading: false,
      Type: 'pdf',
    });
    this.setState({
      eventAttachments: newReps,
    });

    this.pdFileInput && this.pdFileInput.getWrappedInstance().setValue('');
  };

  removeAttachment(index) {
    const newReps = [...this.state.eventAttachments];
    newReps[index].Removed = true;
    this.setState({
      eventAttachments: newReps,
    });
  }

  addRepetition() {
    const newReps = [...this.state.eventRepetitions];
    const id = _.isEmpty(this.state.eventRepetitions) ? 0 : _.maxBy(this.state.eventRepetitions, 'Id').Id + 1;

    let newDate = moment().format('YYYY-MM-DD');
    let DateText = moment().format('DD/MM/YYYY');
    let AllDay = false;
    let Time = '12:00';
    let EndTime;
    let eventToCopy = null;

    if (!_.isEmpty(newReps) && !_.isEmpty(this.state.upcomingReps)) {
      const UPS = [...this.state.upcomingReps];
      let goowoo = [];
      UPS.forEach((e) => {
        if (e != null && !e.Removed) {
          goowoo.push(e);
        }
      });
      if (!_.isEmpty(goowoo)) {
        eventToCopy = { ...goowoo[goowoo.length - 1] };
      }
      if (eventToCopy != null) {
        newDate = moment(moment(eventToCopy.Date, 'YYYY-MM-DD').add(7, 'days')).format('YYYY-MM-DD');
        DateText = moment(moment(eventToCopy.DateText, 'DD/MM/YYYY').add(7, 'days')).format('DD/MM/YYYY');
        AllDay = eventToCopy.AllDay;
        Time = eventToCopy.Time;
        EndTime = eventToCopy.EndTime;
      }
    }

    newReps.push({
      Edit: true,
      Id: id,
      Date: newDate,
      DateText,
      AllDay,
      Time,
      EndTime,
      Location: eventToCopy == null ? '' : eventToCopy.Location,
      TicketCount: eventToCopy == null ? '' : eventToCopy.TicketCount,
      New: true,
    });
    const upcomingReps = [...this.state.upcomingReps];
    const index = _.findIndex(newReps, (rep) => {
      return rep.Id === id;
    });
    upcomingReps.push(newReps[index]);
    this.setState({
      eventRepetitions: newReps,
      upcomingReps,
    });
  }

  onScrollTimeslots = (e) => {
    this.setState({
      timeslotScrollOffset: e.target.scrollLeft,
      visibleTimeslotStart: Math.floor((e.target.scrollLeft + 150) / 266), // 266 is width + margin of timeslot
    });
  };

  onScrollPage = (e) => {
    if (e.target.getAttribute('id') !== 'timeslotsContainer') {
      this.setState({
        pageScrollOffset: e.target.scrollTop,
      });
    }
  };

  renderSubCategories() {
    if (_.isEmpty(this.state.eventTag) || !categories[this.state.eventTag] || _.isEmpty(this.state.selectableTags)) {
      return null;
    }
    return (
      <div>
        <div className="fieldLabel">Tags</div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {_.values(this.state.selectableTags).map((tag, i) => {
            return (
              <Tag
                key={tag.Key}
                className="marginTop-8 marginRight-8"
                text={tag.Title}
                onClick={this.toggleTag.bind(this, tag.Key)}
                leftIcon={tag.Active ? 'check' : null}
              />
            );
          })}
        </div>
      </div>
    );
  }

  removeRepetition(rep, e) {
    const { eventTicketDetails, eventRepetitions, selectedTimeSlot } = this.state;

    if (this.props.payment.isPaymentInfoValid(eventTicketDetails) && rep.AttendanceCount && rep.AttendanceCount > 0) {
      window.alert(`There are tickets already purchased for the selected time. Please refund the tickets first.`);
      return;
    }

    e.stopPropagation();
    rep.Removed = true;
    const newReps = [...eventRepetitions];

    const newState = { eventRepetitions: newReps };

    if (selectedTimeSlot === rep.Id) newState.selectedTimeSlot = null;
    this.setState(newState);
  }

  editRepetition(rep, e) {
    e.stopPropagation();
    const edit = _.isUndefined(rep.Edit) ? false : rep.Edit;
    if (edit) {
      if (!this.validateRepetitionDate(rep) || !this.validateRepetitionTime(rep)) {
        this.setState({ showWarnings: true });
        return;
      }
    }
    rep.Edit = !edit;
    const newReps = [...this.state.eventRepetitions];
    this.setState({
      eventRepetitions: newReps,
    });
  }

  canSelectRepetition(rep) {
    if (rep.New) return false;

    if (_.includes(this.props.auth.hidden, 'eventRegistration')) return null;
    if (!validateAccess(this.props.auth.site, 'eventAttendance', this.props.auth, false) && !this.isConvenor()) {
      return false;
    }
    return true;
  }

  selectRepetition(rep) {
    if (!this.canSelectRepetition(rep)) return;

    this.setState(
      {
        selectedTimeSlot: rep.Id,
        showConvenors: false,
      },
      () => {
        this.getEventReps();
        this.getPayments();
      },
    );
  }

  getRepTimeText = (rep) => {
    if (rep.AllDay) {
      return 'All day';
    }
    if (this.validateRepetitionTime(rep)) {
      return `${moment(rep.Time, 'hh:mm').format('h:mma')}${rep.EndTime ? ` - ${moment(rep.EndTime, 'hh:mm').format('h:mma')}` : ''}`;
    }
    return moment().format('h:mma');
  };

  openMakerRepSelector = () => {
    this.setState({
      makerRepSelectorOpen: true,
    });
  };

  closeMakerRepSelector = () => {
    this.setState({
      makerRepSelectorOpen: false,
    });
  };

  getMakerRepText = (rep) => {
    const formattedDate = moment(rep.Date, 'YYYY-MM-DD').format('D MMMM');
    return `${formattedDate} • ${this.getRepTimeText(rep)}`;
  };

  openMaker = (rep) => {
    this.setState({
      makerRepSelectorOpen: false,
      makerOpen: true,
      makerData: {
        title: this.state.eventTitle,
        image: this.refs.imageInput.getWrappedInstance().getValue(),
        description: this.state.eventDescription,
        timeText: this.getMakerRepText(rep),
      },
    });
  };

  closeMaker = () => {
    this.setState({
      makerOpen: false,
      makerData: null,
    });
  };

  onOpenSendAlert = () => {
    this.setState({ sendAlertOpen: true }, this.getEventReps);
  };

  onCloseSendAlert = () => {
    this.setState({ sendAlertOpen: false });
  };

  onChangeAudience = (audienceType, audienceTypeSelection, isAudienceValid) => {
    this.setState({ audienceType, audienceTypeSelection, isAudienceValid });
  };

  renderTimeslot(rep, index, source) {
    const isAdding = this.state.eventId == null || rep.New;
    const isEditing = rep.Edit;
    const isReadOnly = !isAdding && !isEditing;
    const hasDelete = source.length > 1;
    const canManage = this.canManageEvent();
    const isValid = this.validateRep(rep);

    // new/editing time slot
    return (
      <div className={`timeslot${!isValid ? ' timeslot-invalid' : ''}`} key={rep.Id}>
        {source.length > 1 && canManage && (
          <FontAwesome className="removeoption timeslot_remove" name="minus-circle" onClick={this.removeRepetition.bind(this, rep)} />
        )}
        {isReadOnly && canManage && (
          <FontAwesome
            className={`removeoption timeslot_remove ${hasDelete ? 'marginRight-24' : ''}`}
            name="pencil"
            onClick={this.editRepetition.bind(this, rep)}
          />
        )}
        {isEditing && !isAdding && canManage && (
          <FontAwesome
            className={`removeoption timeslot_remove ${hasDelete ? 'marginRight-24' : ''}`}
            name="check"
            onClick={this.editRepetition.bind(this, rep)}
          />
        )}
        <div className="flex flex-center marginBottom-8">
          <GenericInput
            id={`repetitionDate${rep.Id}`}
            value={rep.DateText}
            showError={() => {
              return this.state.showWarnings && !this.validateRepetitionDate(rep);
            }}
            onChange={(e) => this.handleRepetitionDateTextChange(rep.Id, e.target.value)}
            textWrapperStyle={{ marginBottom: 0 }}
            disabled={this.inputsDisabled() || isReadOnly}
            onClick={(e) => this.handleRepetitionChange(rep.Id, 'ShowDatepicker', true)}
            style={{ marginBottom: 0, width: 100 }}
            inputClass="timeslot_dateinput"
          />
          {!isReadOnly && (
            <FontAwesome
              name={rep.ShowDatepicker ? 'chevron-up' : 'chevron-down'}
              className="timeslot_dateopener"
              onClick={(e) => this.handleRepetitionChange(rep.Id, 'ShowDatepicker', !rep.ShowDatepicker)}
            />
          )}
        </div>
        {rep.ShowDatepicker && !isReadOnly && this.state.visibleTimeslotStart <= index && this.state.visibleTimeslotStart + 3 > index && (
          <DatePicker
            selectedDate={rep.Date}
            selectDate={this.handleRepetitionDateChange.bind(this, rep.Id)}
            style={{ marginLeft: -this.state.timeslotScrollOffset, marginTop: -this.state.pageScrollOffset }}
          />
        )}
        {isReadOnly ? (
          <div className="flex flex-center height-30">
            <Text type="body">{this.getRepTimeText(rep)}</Text>
          </div>
        ) : (
          <div className="flex flex-center height-30">
            <CheckBox
              label={'All day'}
              isActive={rep.AllDay}
              onChange={this.handleRepetitionAllDayChange.bind(this, rep.Id, !rep.AllDay)}
              style={{ marginBottom: 0 }}
            />
          </div>
        )}
        {!isReadOnly && !rep.AllDay && (
          <div className="flex flex-center height-30">
            <GenericInput
              id={`repetitionTime${rep.Id}`}
              type="time"
              value={rep.Time}
              showError={() => {
                return this.state.showWarnings && !this.validateRepetitionTime(rep);
              }}
              style={{ marginBottom: 0, marginLeft: 16 }}
              disabled={this.inputsDisabled()}
              inputComponent={
                <TimePicker
                  disabled={this.inputsDisabled() || rep.AllDay}
                  selectedTime={rep.Time}
                  selectTime={this.handleRepetitionTimeChange.bind(this, rep.Id, 'Time')}
                  className="timepicker-condensed"
                  callbackFormat="HH:mm"
                />
              }
            />
            <GenericInput
              id={`repetitionEndTime${rep.Id}`}
              type="time"
              value={rep.EndTime}
              showError={() => {
                return this.state.showWarnings && !this.validateRepetitionTime(rep);
              }}
              style={{ marginBottom: 0, marginLeft: 16 }}
              disabled={this.inputsDisabled()}
              inputComponent={
                <TimePicker
                  disabled={this.inputsDisabled() || rep.AllDay}
                  selectedTime={rep.EndTime}
                  selectTime={this.handleRepetitionTimeChange.bind(this, rep.Id, 'EndTime')}
                  className="timepicker-condensed"
                  callbackFormat="HH:mm"
                  hasClear
                />
              }
            />
          </div>
        )}
        <div className="flex flex-center height-30">
          <div className="timeslot_icon">
            <SVGIcon icon="user" colour={COLOUR_BRANDING_MAIN} />
          </div>

          <GenericInput
            id={`repetitionTicketCount${rep.Id}`}
            type="text"
            placeholder="Attendee limit"
            value={
              isReadOnly
                ? parseInt(rep.TicketCount, 10) === 0
                  ? `${rep.AttendanceCount || 0} attendee${getPluralS(rep.AttendanceCount || 0)}`
                  : `${rep.AttendanceCount || 0}/${rep.TicketCount}`
                : rep.TicketCount
            }
            onChange={(e) => this.handleRepetitionChange(rep.Id, 'TicketCount', e.target.value)}
            style={{ marginBottom: 0, flex: 1 }}
            inputStyle={{ padding: 0, fontSize: 13, height: 20 }}
            disabled={this.inputsDisabled() || isReadOnly}
          />
          {isReadOnly && (
            <Button
              inline
              buttonType="outlined"
              onClick={() => {
                this.selectRepetition(rep);
              }}
              isActive
              compact
            >
              Manage Attendance
            </Button>
          )}
        </div>
        <div className="flex flex-center height-30">
          <div className="timeslot_icon">
            <SVGIcon icon="map-marker" colour={COLOUR_BRANDING_MAIN} />
          </div>
          <GenericInput
            id={`repetitionLocation${rep.Id}`}
            type="text"
            placeholder={isReadOnly ? 'No location' : 'Location'}
            value={rep.Location}
            onChange={(e) => this.handleRepetitionChange(rep.Id, 'Location', e.target.value)}
            style={{ marginBottom: 0, flex: 1 }}
            textWrapperStyle={{ marginBottom: 0 }}
            inputStyle={{ padding: 0, fontSize: 13, height: 20 }}
            disabled={this.inputsDisabled() || isReadOnly}
          />
        </div>
      </div>
    );
  }

  getEventRepetitions = () => {
    const { eventRepetitions } = this.state;
    let source = eventRepetitions;
    source = _.filter(source, (ev) => !ev.Removed && !ev.New);

    return source;
  };

  updateRepAttendance = (reps) => {
    reps.forEach((rep) => {
      const existingRep = _.find(this.state.eventRepetitions, (r) => {
        return r.Id === rep.RepId;
      });
      const attendanceCount = _.sumBy(_.values(rep.Attendees || {}), (a) => {
        return a.isGuest ? 0 : a.Count || 1;
      });
      if (existingRep) {
        existingRep.AttendanceCount = attendanceCount;
      }
    });
    this.setState({
      eventRepetitions: [...this.state.eventRepetitions],
    });
  };

  renderAddAttachment() {
    if (this.inputsDisabled()) {
      return null;
    }
    return (
      <FileInput
        ref={(ref) => {
          this.pdFileInput = ref;
        }}
        style={{ height: 120, width: 240 }}
        refreshCallback={this.addAttachment}
        accept="application/pdf"
        simpleStyle
      />
    );
  }

  renderAttachments() {
    return (
      <div className="optionsContent_bottom">
        <FormGroup controlId="eventAttachment">
          {this.state.eventAttachments.map((attachment, index) => {
            if (attachment.Removed) {
              return null;
            }
            return (
              <div key={index} className="pdfAttachmentInput">
                <img
                  src="https://s3-ap-southeast-2.amazonaws.com/pluss60-dev-media/pluss/document.svg"
                  className="pdfAttachmentInput_icon"
                  alt="file"
                />
                <Text type="formTitleSmall" className="pdfAttachmentInput_title">
                  {getFileName(attachment.Source)}
                </Text>
                <GenericInput
                  id={`eventAttachmentTitle${index}`}
                  label="Title"
                  type="text"
                  placeholder="Title"
                  value={attachment.Title}
                  onChange={(e) => this.handleAttachmentChange(index, 'Title', e)}
                  disabled={this.inputsDisabled()}
                  alwaysShowLabel
                  style={{ margin: 0, flex: 1 }}
                />
                <P60Icon
                  className="removeoption pdfAttachmentInput_remove"
                  icon="remove-circle"
                  onClick={() => {
                    this.removeAttachment(index);
                  }}
                />
              </div>
            );
          })}
          {this.renderAddAttachment()}
        </FormGroup>
      </div>
    );
  }

  onBroadcasterClose = () => {
    this.setState({ broadcasterSelectorVisible: false });
  };

  onBroadcasterSave = (liveBroadcasters) => {
    this.setState({ liveBroadcasters });
  };

  addBroadcaster = () => {
    this.setState({ broadcasterSelectorVisible: true });
  };

  renderBroadcasterSelector() {
    if (!this.state.broadcasterSelectorVisible) return null;

    return (
      <AddUserPopup
        label="Add Broadcaster"
        onClose={this.onBroadcasterClose}
        onSave={this.onBroadcasterSave}
        userList={this.props.users}
        selectionList={this.state.liveBroadcasters}
      />
    );
  }

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return (
      <SuccessPopup
        text={`${this.state.isEveryday ? 'Facility' : 'Event'} has been ${this.state.eventId != null ? 'edited' : 'added'}`}
        buttons={[
          {
            type: 'secondary',
            onClick: this.clearForm.bind(this),
            text: `Create new ${this.state.isEveryday ? 'facility' : 'event'}`,
          },
          {
            type: 'outlined',
            onClick: this.clearSuccess.bind(this),
            text: `Add another ${this.state.isEveryday ? 'facility' : 'event'} with same info`,
          },
          {
            type: 'outlined',
            onClick: () => {
              window.history.back();
            },
            text: `Go to home`,
          },
        ]}
      />
    );
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <div className="flex flex-between flex-reverse  flex-1">
        <div>
          <Button
            inline
            buttonType="tertiary"
            onClick={() => {
              window.history.back();
            }}
            isActive
            style={{ marginRight: 16 }}
          >
            {this.canManageEvent() ? 'Cancel' : 'Back'}
          </Button>
          {this.canManageEvent() && (
            <Button inline buttonType="primary" onClick={this.handleSubmit} isActive={this.validateForm()}>
              Save
            </Button>
          )}
        </div>
        {this.state.eventId && this.canManageEvent() && (
          <Button inline buttonType="outlined" onClick={this.openMakerRepSelector} isActive leftIcon="print">
            Create Poster
          </Button>
        )}
      </div>
    );
  }

  getCount(list) {
    if (list.length === 0) {
      return 0;
    }
    return _.filter(list, (ev) => {
      return !ev.Removed;
    }).length;
  }

  renderRecurringInfo() {
    const { recurrenceInfo } = this.state;
    const isReadOnly = false;
    const isValid = this.validateRepetitionTime(recurrenceInfo);

    return (
      <div className={`timeslot${!isValid ? ' timeslot-invalid' : ''}`}>
        <div className="flex flex-center height-30">
          <CheckBox
            label={'All day'}
            isActive={recurrenceInfo.AllDay}
            onChange={() => this.handleRecInfoChange('AllDay', !recurrenceInfo.AllDay)}
            style={{ marginBottom: 0 }}
          />
        </div>
        {!isReadOnly && !recurrenceInfo.AllDay && (
          <div className="flex flex-center height-30">
            <GenericInput
              id="recurrenceTime"
              type="time"
              value={recurrenceInfo.Time}
              showError={() => {
                return this.state.showWarnings && !this.validateRepetitionTime(recurrenceInfo);
              }}
              style={{ marginBottom: 0, marginLeft: 16 }}
              disabled={this.inputsDisabled()}
              inputComponent={
                <TimePicker
                  disabled={this.inputsDisabled() || recurrenceInfo.AllDay}
                  selectedTime={recurrenceInfo.Time}
                  selectTime={(time) => this.handleRecInfoChange('Time', time)}
                  className="timepicker-condensed"
                  callbackFormat="HH:mm"
                />
              }
            />
            <GenericInput
              id="recurrenceEndTime"
              type="time"
              value={recurrenceInfo.EndTime}
              showError={() => {
                return this.state.showWarnings && !this.validateRepetitionTime(recurrenceInfo);
              }}
              style={{ marginBottom: 0, marginLeft: 16 }}
              disabled={this.inputsDisabled()}
              inputComponent={
                <TimePicker
                  disabled={this.inputsDisabled() || recurrenceInfo.AllDay}
                  selectedTime={recurrenceInfo.EndTime}
                  selectTime={(time) => this.handleRecInfoChange('EndTime', time)}
                  className="timepicker-condensed"
                  callbackFormat="HH:mm"
                  hasClear
                />
              }
            />
          </div>
        )}
        <div className="flex flex-center height-30">
          <div className="timeslot_icon">
            <SVGIcon icon="user" colour={COLOUR_BRANDING_MAIN} />
          </div>

          <GenericInput
            id="recurrenceTicketCount"
            type="text"
            placeholder="Attendee limit"
            value={
              isReadOnly
                ? parseInt(recurrenceInfo.TicketCount, 10) === 0
                  ? `${recurrenceInfo.AttendanceCount || 0} attendee${getPluralS(recurrenceInfo.AttendanceCount || 0)}`
                  : `${recurrenceInfo.AttendanceCount || 0}/${recurrenceInfo.TicketCount}`
                : recurrenceInfo.TicketCount
            }
            onChange={(e) => this.handleRecInfoChange('TicketCount', e.target.value)}
            style={{ marginBottom: 0, flex: 1 }}
            inputStyle={{ padding: 0, fontSize: 13, height: 20 }}
            disabled={this.inputsDisabled() || isReadOnly}
          />
        </div>
        <div className="flex flex-center height-30">
          <div className="timeslot_icon">
            <SVGIcon icon="map-marker" colour={COLOUR_BRANDING_MAIN} />
          </div>
          <GenericInput
            id="recurrenceLocation"
            type="text"
            placeholder={isReadOnly ? 'No location' : 'Location'}
            value={recurrenceInfo.Location}
            onChange={(e) => this.handleRecInfoChange('Location', e.target.value)}
            style={{ marginBottom: 0, flex: 1 }}
            textWrapperStyle={{ marginBottom: 0 }}
            inputStyle={{ padding: 0, fontSize: 13, height: 20 }}
            disabled={this.inputsDisabled() || isReadOnly}
          />
        </div>
      </div>
    );
  }

  renderRecurringSection() {
    const { recurrenceInfo, eventId } = this.state;
    const isAdding = eventId == null;

    return (
      <div className="timeRecurringContainer">
        <ReactRRuleGenerator
          onInit={(rule) => this.handleRecInfoChange('RecurrenceRule', rule)}
          onChange={(rule) => this.handleRecInfoChange('RecurrenceRule', rule)}
          config={{
            repeat: ['Weekly', 'Monthly'],
            end: ['Never', 'On date'],
            weekStartsOnSunday: false,
            defaultWeekDays: ['fri'],
            // monthly: 'on',
            hideStart: false,
            hideEnd: false,
            hideError: false,
          }}
          value={recurrenceInfo.RecurrenceRule}
          className="ruleGenerator"
          readOnly={!isAdding}
        />
        {this.renderRecurringInfo()}
      </div>
    );
  }

  renderTimeslots() {
    const { isEveryday, isSourceGlobal, globalAllowTimeEdit, eventRepetitions, recurrenceInfo } = this.state;
    if (isEveryday) return null;
    if (isSourceGlobal && !globalAllowTimeEdit) return null;

    const isAdding = this.state.eventId == null;
    const isRecurring = recurrenceInfo.IsRecurring || false;
    const orderedSource = _.filter(eventRepetitions, (ev) => {
      return !ev.Removed;
    });
    const source = orderedSource.reverse();
    return (
      <div>
        <Text type="formTitleSmall" className="marginBottom-16">
          Time Slots
        </Text>
        {isAdding ? (
          <div className="marginBottom-16">
            <RadioButton
              label="Is this a recurring event?"
              isActive={isRecurring}
              options={[
                { Label: 'Yes', Value: true, onChange: () => this.handleRecInfoChange('IsRecurring', true) },
                { Label: 'No', Value: false, onChange: () => this.handleRecInfoChange('IsRecurring', false) },
              ]}
              disabled={this.inputsDisabled()}
            />
          </div>
        ) : null}
        {isRecurring ? this.renderRecurringSection() : null}
        {!isRecurring || !isAdding ? (
          <div id="timeslotsContainer" className="timeslotsContainer" onScroll={this.onScrollTimeslots}>
            <div
              className="timeslot timeslot-add"
              onClick={() => {
                this.addRepetition();
              }}
            >
              <div className="timeslot_addinner">
                <FontAwesome className="timeslot_addicon" name="plus-circle" />
                <p className="timeslot_addtext">Add New Time Slot</p>
              </div>
            </div>
            {source.map((rep, index) => {
              return this.renderTimeslot(rep, index, orderedSource);
            })}
          </div>
        ) : null}
      </div>
    );
  }

  renderEverydayOptions() {
    if (!this.state.isEveryday) {
      return null;
    }
    return (
      <GenericInput
        label={'Location'}
        id="eventLocation"
        componentClass="textarea"
        placeholder="Location"
        value={this.state.eventLocation}
        onChange={(e) => this.handleChange(e)}
        disabled={this.inputsDisabled()}
      />
    );
  }

  renderSendAlert() {
    if (!this.canManageEvent() || !this.state.eventId) return null;

    return (
      <div className="padding-32 paddingVertical-40">
        <Button inline buttonType="outlined" onClick={this.onOpenSendAlert} isActive leftIcon="bell">
          Send Alert
        </Button>
      </div>
    );
  }

  renderConvenors() {
    return null;
    // if (this.state.isEveryday || this.isOnlyConvenor() || this.state.isSourceGlobal) {
    //   return null;
    // }
    // return (
    //   <div
    //     className={`padding-32 paddingVertical-40 bottomDivideBorder ${this.state.hasConvenors ? 'pointer' : ''} ${
    //       this.state.showConvenors ? 'sideSection--highlighted' : ''
    //     }`}
    //     onClick={this.onClickConvenors.bind(this)}
    //   >
    //     <p className="text-sectionTitle">CONVENORS</p>
    //     <div style={{ marginTop: 16 }}>
    //       <RadioButton
    //         label="Does this event have convenors?"
    //         isActive={this.state.hasConvenors}
    //         options={[
    //           {
    //             Label: 'Yes',
    //             Value: true,
    //             onChange: () => this.setState({ hasConvenors: true, showConvenors: true, selectedTimeSlot: null }),
    //           },
    //           { Label: 'No', Value: false, onChange: () => this.setState({ hasConvenors: false, showConvenors: false }) },
    //         ]}
    //         disabled={this.inputsDisabled()}
    //       />
    //       <div className="genericInput-help" style={{ marginTop: 4 }}>
    //         Convenors are primary users who can manage details of this event.
    //       </div>
    //       {this.state.hasConvenors && (
    //         <p className="fieldLabel" style={{ marginTop: 8, marginBottom: 0 }}>
    //           {this.state.convenors.length} convenor{this.state.convenors.length === 1 ? '' : 's'} selected
    //         </p>
    //       )}
    //     </div>
    //   </div>
    // );
  }

  renderRemoveConvenor(user) {
    return (
      <div onClick={this.removeConvenor.bind(this, user)}>
        <P60Icon className="actionIcon" icon="remove-circle" />
      </div>
    );
  }

  renderTick(user) {
    if (
      !_.some(this.state.convenors, (u) => {
        return u.userId === user.Id;
      })
    ) {
      return null;
    }
    return (
      <div className={`aCheck aCheck--active`}>
        <div className="aCheck-Tick">
          <FontAwesome className="aCheck-font" name={'check'} />
        </div>
      </div>
    );
  }

  renderSubText(user) {
    if (_.isEmpty(user.unit)) {
      return null;
    }
    return (
      <p className="text-subtitle" style={{ margin: 0 }}>
        {user.unit}
      </p>
    );
  }

  renderConvenorResults() {
    if (this.state.userSearch == null) {
      return null;
    }
    let source = _.sortBy(
      _.filter(this.props.users, (ev) => {
        return ev.displayName.toLowerCase().indexOf(this.state.userSearch.toLowerCase()) > -1 && ev.category === 'resident';
      }),
      'displayName',
    );

    return source.map((user, index) => {
      return (
        <UserListing
          user={user}
          onClick={this.addConvenor.bind(this, user)}
          key={user.id}
          rightContent={this.renderTick(user)}
          subContent={this.renderSubText(user)}
          size={50}
        />
      );
    });
  }

  renderConvenorSide() {
    if (!this.state.showConvenors || this.state.success || this.state.isEveryday || this.state.selectedTimeSlot != null) {
      return null;
    }
    return (
      <OverlayPageSection className="pageSectionWrapper--newPopupSide pageSectionWrapper--newPopupSide--purple">
        <div className="paddingVertical-40">
          <div className="paddingHorizontal-32">
            <p className="text-sectionTitle text-white">CONVENORS</p>
          </div>
          <div className="marginTop-24 paddingHorizontal-32">
            {this.state.convenors.map((user, index) => {
              return <UserListing user={user} key={user.userId} whiteText rightContent={this.renderRemoveConvenor(user)} />;
            })}
          </div>
          <div className="marginTop-24">
            <div className="paddingHorizontal-32 paddingVertical-16" style={{ backgroundColor: '#fff' }}>
              {' '}
              {/* Search for peeps */}
              <GenericInput
                id="userSearch"
                type="text"
                label="Search for User"
                placeholder="Search for user"
                value={this.state.userSearch}
                onChange={(e) => this.handleSearchChange(e)}
              />
              {this.renderConvenorResults()}
            </div>
          </div>
        </div>
      </OverlayPageSection>
    );
  }

  renderFeatured() {
    if (this.state.isEveryday) {
      return null;
    }
    return (
      <div className="optionsContent_bottom">
        <RadioButton
          label="Is this a featured event?"
          isActive={this.state.isFeatured}
          options={[
            { Label: 'Yes', Value: true, onChange: () => this.setState({ isFeatured: true }) },
            { Label: 'No', Value: false, onChange: () => this.setState({ isFeatured: false }) },
          ]}
          disabled={this.inputsDisabled()}
        />
        <div className="genericInput-help" style={{ marginTop: 4 }}>
          Featured events will be highlighted on the Home page.
        </div>
      </div>
    );
  }

  renderTvMode() {
    if (this.state.isEveryday) {
      return null;
    }
    return null;
  }

  renderNotify() {
    if (this.state.eventId || _.includes(this.props.auth.hidden, 'eventNotifications')) {
      return null;
    }
    return (
      <div className="marginTop-16">
        <RadioButton
          label="Do you want to send a notification with this event?"
          isActive={this.state.shouldNotify}
          options={[
            { Label: 'Yes', Value: true, onChange: () => this.setState({ shouldNotify: true }) },
            { Label: 'No', Value: false, onChange: () => this.setState({ shouldNotify: false }) },
          ]}
        />
        <div className="genericInput-help" style={{ marginTop: 4 }}>
          This will send an alert to all users who have enabled push notifications.
        </div>
      </div>
    );
  }

  renderGlobalEventOptions() {
    if (!this.state.isGlobalEvent) {
      return null;
    }
    return (
      <div className="padding-60 paddingVertical-40 bottomDivideBorder">
        <p className="text-sectionTitle">GLOBAL EVENT OPTIONS</p>
        <CheckBox
          className="marginTop-16"
          label="Allow description to be changed"
          isActive={this.state.globalAllowDescriptionEdit}
          onChange={() => {
            this.setState({ globalAllowDescriptionEdit: !this.state.globalAllowDescriptionEdit });
          }}
        />
        <CheckBox
          className="marginTop-16"
          label="Allow date/time to be changed"
          isActive={this.state.globalAllowTimeEdit}
          onChange={() => {
            this.setState({ globalAllowTimeEdit: !this.state.globalAllowTimeEdit });
          }}
        />
      </div>
    );
  }

  renderGlobalInfo() {
    if (!this.state.isSourceGlobal) {
      return null;
    }
    let editText = null;
    if (this.state.globalAllowTimeEdit && this.state.globalAllowDescriptionEdit) {
      editText = 'description and time slots';
    } else if (this.state.globalAllowTimeEdit) {
      editText = 'time slots';
    } else if (this.state.globalAllowDescriptionEdit) {
      editText = 'description';
    }

    const text = editText ? `This event only allows for the ${editText} to be edited` : 'This event does not allow you to edit';
    return <p className="highlightedHelp">{text}</p>;
  }

  renderOrganiser() {
    const inputsDisabled = this.inputsDisabled();
    return (
      <div className="optionsContent_bottom" style={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* organiser */}
        <GenericInput
          id="organiser"
          type="text"
          label="Organiser Name"
          placeholder="Organiser"
          value={this.state.organiser}
          onChange={(e) => this.handleChange(e)}
          isValid={() => {
            return this.validateOrganiser();
          }}
          showError={() => {
            return this.state.showWarnings && !this.validateOrganiser();
          }}
          isRequired={!_.isEmpty(this.state.phone)}
          style={{ width: '100%', marginRight: 24 }}
          disabled={inputsDisabled}
          alwaysShowLabel
        />
        {/* phone */}
        <GenericInput
          id="phone"
          type="text"
          label="Contact Number"
          placeholder="0400 000 000"
          value={this.state.phone}
          onChange={(e) => this.handleChange(e)}
          style={{ width: '100%', marginLeft: 24 }}
          disabled={inputsDisabled}
          alwaysShowLabel
        />
      </div>
    );
  }

  renderBookingOptions() {
    const inputsDisabled = this.inputsDisabled();
    return (
      <div className="optionsContent_bottom">
        <RadioButton
          label="In App Bookings & Reservations?"
          isActive={this.state.hasRegistration}
          options={[
            { Label: 'On', Value: true, onChange: () => this.setState({ hasRegistration: true }) },
            { Label: 'Off', Value: false, onChange: () => this.setState({ hasRegistration: false }) },
          ]}
          disabled={inputsDisabled}
        />
        {!this.state.hasRegistration && (
          <RadioButton
            style={{ marginTop: 16 }}
            label="Do you want to allow a different booking option?"
            isActive={this.state.hasBookings}
            options={[
              { Label: 'Yes', Value: true, onChange: () => this.setState({ hasBookings: true }) },
              { Label: 'No', Value: false, onChange: () => this.setState({ hasBookings: false }) },
            ]}
            disabled={inputsDisabled}
          />
        )}
        {this.state.hasBookings && (
          <>
            <div style={{ marginTop: 16 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '100%', maxWidth: '50%', marginRight: 24 }}>
                  <DropdownInput
                    id="bookingType"
                    label="Booking Type"
                    placeholder="Booking Type"
                    value={this.getSelectedBookingType().Title}
                    options={bookingTypes}
                    onSelect={this.selectBookingType.bind(this)}
                    disabled={inputsDisabled}
                  />
                </div>
                {this.state.bookingData.type === 'EmailRequest' && (
                  <GenericInput
                    id="emailRequestBooking"
                    type="text"
                    label="Email address"
                    placeholder="Email address"
                    help="Enter an email address that receives these requests"
                    showError={() => {
                      return this.state.showWarnings && this.isEmpty(this.state.emailRequestBooking);
                    }}
                    value={this.state.emailRequestBooking}
                    onChange={(e) => this.handleChange(e)}
                    style={{ width: '100%', marginLeft: 24 }}
                    disabled={inputsDisabled}
                    alwaysShowLabel
                  />
                )}
              </div>
              {this.state.bookingData.type === 'External' && (
                <GenericInput
                  id="buttonLink"
                  type="text"
                  label="Button Link"
                  placeholder="External link (https://...)"
                  showError={() => {
                    return this.state.showWarnings && this.isEmpty(this.state.buttonLink);
                  }}
                  value={this.state.buttonLink}
                  onChange={(e) => this.handleChange(e)}
                  disabled={inputsDisabled}
                  alwaysShowLabel
                />
              )}
            </div>
            {this.state.bookingData.type !== 'NoRequest' && (
              <GenericInput
                id="requestButtonText"
                type="text"
                label="Button text"
                placeholder="Request booking"
                value={this.state.requestButtonText}
                onChange={(e) => this.handleChange(e)}
                alwaysShowLabel
              />
            )}
            {this.state.bookingData.type === 'EmailRequest' && (
              <div className="marginTop-40">
                <Text type="formTitleSmall">Customise Confirmation Popup</Text>
                <Text type="help" className="marginBottom-10">
                  Use the fields to customise the message displayed in the popup when users submit their request.
                </Text>
                <GenericInput
                  id="confirmationTitle"
                  type="text"
                  label="Confirmation title"
                  placeholder="Request received"
                  value={this.state.confirmationTitle}
                  onChange={(e) => this.handleChange(e)}
                  alwaysShowLabel
                />
                <GenericInput
                  id="confirmationText"
                  type="textarea"
                  label="Confirmation text"
                  placeholder="Thanks for submitting your request. A member from our team will be in touch with you."
                  value={this.state.confirmationText}
                  onChange={(e) => this.handleChange(e)}
                  alwaysShowLabel
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  }

  renderForm() {
    if (this.state.success) {
      return null;
    }

    const inputsDisabled = this.inputsDisabled();
    return (
      <div>
        <div className="padding-60 paddingVertical-40 bottomDivideBorder">
          <Text type="formTitleLarge" className="marginBottom-24">
            {this.state.eventId == null ? 'New' : 'Edit'} Event
          </Text>
          {this.renderGlobalInfo()}
          {/* title */}
          <div className="flex flex-reverse">
            <ImageInput
              ref="imageInput"
              label="IMAGE"
              limit={1}
              isRequired
              refreshCallback={this.onImageUpdated}
              showError={() => {
                return this.state.showWarnings && !this.validateImage();
              }}
              disabled={inputsDisabled}
              containerStyle={{ marginLeft: 40 }}
            />
            <div className="flex-1">
              <GenericInput
                id="eventTitle"
                type="text"
                label="Title"
                placeholder="Insert title here"
                isValid={() => {
                  return !_.isEmpty(this.state.eventTitle);
                }}
                showError={() => {
                  return this.state.showWarnings && this.isEmpty(this.state.eventTitle);
                }}
                value={this.state.eventTitle}
                onChange={(e) => this.handleChange(e)}
                isRequired
                disabled={inputsDisabled}
                alwaysShowLabel
              />
              {/* description */}
              <GenericInput
                id="eventDescription"
                label="Description"
                type="textarea"
                componentClass="textarea"
                placeholder="Insert description here"
                value={this.state.eventDescription}
                onChange={(e) => this.handleChange(e)}
                inputStyle={{
                  height: 120,
                }}
                disabled={inputsDisabled && !this.state.globalAllowDescriptionEdit}
                alwaysShowLabel
                help={
                  <a
                    onClick={() => {
                      this.toggleTextFormat(true);
                    }}
                    className="pointer"
                  >
                    Text formatting
                  </a>
                }
              />
            </div>
          </div>
          {this.renderTimeslots()}
          {this.renderEverydayOptions()}
          {/* other stuff */}
        </div>
        {this.renderGlobalEventOptions()}
        {this.renderOptionsSection()}
      </div>
    );
  }

  showPast() {
    this.setState({ showPast: !this.state.showPast });
  }

  renderComments() {
    if (_.includes(this.props.auth.hidden, 'eventComments')) return null;
    return (
      <div className="marginTop-16">
        <RadioButton
          label="Do you want to allow comments on this event?"
          isActive={this.state.allowComments}
          options={[
            { Label: 'Yes', Value: true, onChange: () => this.setState({ allowComments: true }) },
            { Label: 'No', Value: false, onChange: () => this.setState({ allowComments: false }) },
          ]}
        />
        {this.state.allowComments && (
          <CheckBox
            className="marginTop-16"
            label="Users can only see their own comments"
            isActive={this.state.commentVisibilityLimited}
            onChange={() => {
              this.setState({ commentVisibilityLimited: !this.state.commentVisibilityLimited });
            }}
          />
        )}
      </div>
    );
  }

  renderConfirmation() {
    return (
      <div className="marginTop-16">
        <RadioButton
          label="Do you want to send a confirmation email when users register for this event?"
          isActive={this.state.confirmationEmail}
          options={[
            { Label: 'Yes', Value: true, onChange: () => this.setState({ confirmationEmail: true }) },
            { Label: 'No', Value: false, onChange: () => this.setState({ confirmationEmail: false }) },
          ]}
        />
      </div>
    );
  }

  renderReminder() {
    return (
      <div className="marginTop-16">
        <RadioButton
          label="Do you want to enable reminders for this event?"
          isActive={this.state.reminder}
          options={[
            { Label: 'Yes', Value: true, onChange: () => this.setState({ reminder: true }) },
            { Label: 'No', Value: false, onChange: () => this.setState({ reminder: false }) },
          ]}
          help="This will send an alert to all registered users who have enabled push notifications as well as an email 24 hours prior to the start of the event."
        />
      </div>
    );
  }

  renderAudience() {
    const { auth } = this.props;
    const { audienceType, audienceTypeSelection } = this.state;

    return (
      <div className="optionsContent_bottom">
        <AudienceSelector
          onChange={this.onChangeAudience}
          auth={auth}
          custom
          audienceType={audienceType}
          audienceTypeSelection={audienceTypeSelection}
          disallowSingleUsers
          disallowUserType
        />
      </div>
    );
  }

  renderPublishOptions() {
    return (
      <div className="optionsContent_bottom optionsContent_bottom-noTopPadding">
        {this.renderNotify()}
        {this.renderTvMode()}
        {this.renderComments()}
        {this.renderConfirmation()}
        {this.renderReminder()}
      </div>
    );
  }

  renderOnchargeFees() {
    if (!this.props.payment.enabled) return null;
    return (
      <div className="marginTop-16 marginBottom-16">
        <RadioButton
          label="Do you want to oncharge fees?"
          isActive={this.state.onchargeFees}
          options={[
            { Label: 'Yes', Value: true, onChange: () => this.setState({ onchargeFees: true }) },
            { Label: 'No', Value: false, onChange: () => this.setState({ onchargeFees: false }) },
          ]}
        />
        <div className="genericInput-help" style={{ marginTop: 4 }}>
          This will request users to pay the fees on top of the price.
        </div>
      </div>
    );
  }

  renderTicketDetails() {
    const { eventTicketDetails, showWarnings, onchargeFees } = this.state;
    const { merchants, payment } = this.props;

    const readOnly = this.isPaymentReadOnly();
    return (
      <div className="optionsContent_bottom">
        {payment.enabled && (
          <DropdownInput
            id="selectedMerchant"
            label="Account"
            placeholder="Select Account"
            ignoreValue="Select Account"
            value={this.getSelectedMerchant().Title}
            //isRequired
            //isValid={() => { return this.validateCategory(); }}
            //showError={() => { return this.state.showWarnings && !this.validateCategory(); }}
            options={this.getMerchantOptions()}
            onSelect={this.selectMerchant.bind(this)}
            disabled={readOnly}
          />
        )}
        {this.renderOnchargeFees()}
        {eventTicketDetails.categories.map((ticket, index) => {
          const priceText = this.props.payment.getPriceText(ticket);
          const categoryValid = this.props.payment.isCategoryValid(ticket.category, eventTicketDetails);
          const priceValid = this.props.payment.isPriceValid(ticket.price);
          const { feeStructure } = this.state;
          let fee, price, shouldShowFee;
          if (priceValid && feeStructure) {
            price = this.props.payment.textToPrice(ticket.price);
            fee = this.props.payment.getFee(feeStructure, price);
            shouldShowFee = true;
          }
          return (
            <div key={index}>
              <div className="flex flex-row flex-center">
                <Text type="formQuestionNumber" style={{ width: 30, textAlign: 'left', marginBottom: 0 }}>
                  {index + 1}
                </Text>
                <GenericInput
                  id={`category${index}`}
                  type="text"
                  label={'Pricing Category Name'}
                  placeholder={'e.g. Adult'}
                  value={ticket.category}
                  onChange={(e) => this.onChangeTicketCategory(index, e.target.value)}
                  isValid={() => categoryValid}
                  showError={() => showWarnings && !categoryValid}
                  errorMessage={'Unique Name Required'}
                  style={{ width: '100%', maxWidth: 270, marginRight: 50 }}
                  disabled={readOnly}
                  alwaysShowLabel
                />
                <GenericInput
                  id={`price${index}`}
                  type="text"
                  label={'Price'}
                  placeholder={'e.g. $1.23'}
                  value={priceText}
                  onChange={(e) => this.onChangeTicketPrice(index, e.target.value)}
                  isValid={() => priceValid}
                  showError={() => showWarnings && !priceValid}
                  errorMessage={'Amount Required'}
                  style={{ width: '100%', maxWidth: 230, marginRight: 15 }}
                  disabled={readOnly}
                  alwaysShowLabel
                />
                {!readOnly ? (
                  <div onClick={() => this.onRemovePriceCategory(index)}>
                    <FontAwesome name="minus-circle" className="removeoption" />
                  </div>
                ) : null}
              </div>
              {payment.enabled && shouldShowFee && (
                <div className="marginBottom-16">
                  <Text type="help">
                    This ticket will incur {this.props.payment.formatCurrency(fee)} in fees and paid by{' '}
                    {onchargeFees ? 'the user' : 'the connected account'}.{' '}
                    {this.props.payment.formatCurrency(price - (onchargeFees ? 0 : fee))} will be transferred to the connected account.
                  </Text>
                </div>
              )}
            </div>
          );
        })}
        {!readOnly ? (
          <div className="clearfix addoption" onClick={this.onAddPriceCategory}>
            <P60Icon className="addoption_plus" icon="add-circle" />
            <div className="fillSpace">
              <p className="addoption_text">Add New Category</p>
            </div>
          </div>
        ) : null}
        {!_.isEmpty(merchants) ? (
          <div className="fieldLabel fieldLabel-warning marginTop-10 textAlign-right">
            {readOnly ? 'Prices cannot be changed once event has been created' : 'WARNING: Prices cannot be changed later'}
          </div>
        ) : null}
      </div>
    );
  }

  startAddingLocationTag = () => {
    this.setState({
      addingLocationTag: true,
      locationTagInput: '',
    });
  };

  detachLocationTag = (t) => {
    this.setState({
      locationTags: _.filter(this.state.locationTags, (lt) => {
        return lt !== t;
      }),
    });
  };

  saveLocationTag = () => {
    if (_.isEmpty(this.state.locationTagInput)) {
      return;
    }
    this.attachLocationTag(this.state.locationTagInput);
    this.setState({
      availableLocationTags: _.uniq([...this.state.availableLocationTags, this.state.locationTagInput]),
      locationTagInput: '',
      addingLocationTag: false,
    });
  };

  attachLocationTag = (t) => {
    this.setState({
      locationTags: _.uniq([...this.state.locationTags, t]),
    });
  };

  deleteLocationTag = (t) => {
    if (!window.confirm('Are you sure you want to delete this tag? This will not remove the tag from other events.')) {
      return;
    }
    eventActions.deleteLocationTag(this.props.auth.site, t);

    this.setState({
      availableLocationTags: _.filter(this.state.availableLocationTags, (lt) => {
        return lt !== t;
      }),
    });
  };

  renderTags() {
    const { locationTags, addingLocationTag, isEditingLocationTags, locationTagInput, availableLocationTags } = this.state;
    const availableTags = _.filter(availableLocationTags || [], (t) => {
      return !_.includes(locationTags, t);
    });
    return (
      <div className="optionsContent_bottom">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {eventsHaveTags && (
            <div style={{ width: '100%', maxWidth: 325, marginRight: 24 }}>
              <DropdownInput
                id="eventTag"
                label="Category"
                placeholder="Category"
                ignoreValue="Category"
                value={this.getSelectedCategory().Title}
                //isRequired
                //isValid={() => { return this.validateCategory(); }}
                //showError={() => { return this.state.showWarnings && !this.validateCategory(); }}
                options={categories}
                onSelect={this.selectCategory.bind(this)}
                disabled={this.inputsDisabled()}
              />
              {/* secondary tags */}
              {this.renderSubCategories()}
            </div>
          )}
        </div>
        <Text type="formTitleSmall" className={eventsHaveTags ? 'marginTop-16' : ''}>
          Location Tags
        </Text>
        <Text type="help" className="marginTop-8">
          Location tags allow users to filter events by a geographical location. These can be the name of a suburb, town or region.
        </Text>
        <Text type="formLabel" className="marginTop-8">
          Assigned Location Tags
        </Text>
        <div className="userTagsSite">
          <div className="userTagsSite_tags">
            {_.isEmpty(locationTags) && (
              <Text type="body" className="text-italic">
                No location tags yet
              </Text>
            )}
            {locationTags.map((t, i) => {
              return (
                <Tag
                  key={t}
                  className={i > 0 ? 'marginLeft-16' : ''}
                  text={t}
                  rightIcon="remove"
                  rightClick={() => {
                    this.detachLocationTag(t);
                  }}
                />
              );
            })}
          </div>
        </div>
        <Text type="formLabel" className="marginTop-32">
          Available Location Tags
        </Text>
        <div className="userTagsAvailableSite">
          <div className="userTagsAvailableSite_tags">
            {!addingLocationTag && !isEditingLocationTags && (
              <Tag
                className="tag-outlined tag-minSize"
                centerClass="tag_center-spaceAround"
                text="Add Tag"
                onClick={() => {
                  this.startAddingLocationTag();
                }}
                leftIcon="plus"
                leftIconStyle={{ paddingTop: 2 }}
              />
            )}
            {addingLocationTag && !isEditingLocationTags && (
              <Tag
                className="tag-outlined tag-minSize"
                rightIcon="check-circle"
                rightIconStyle={{ fontSize: 16 }}
                rightClick={this.saveLocationTag}
              >
                <GenericInput
                  value={locationTagInput}
                  id={`locationTagInput`}
                  onChange={(e) => {
                    this.setState({ locationTagInput: e.target.value });
                  }}
                  autofocus
                  className="tag_input"
                />
              </Tag>
            )}
            {availableTags.map((t) => {
              return (
                <Tag
                  key={t}
                  className="marginLeft-16"
                  text={t}
                  onClick={
                    isEditingLocationTags
                      ? undefined
                      : () => {
                          this.attachLocationTag(t);
                        }
                  }
                  rightIcon="trash"
                  rightClick={(e) => {
                    e.stopPropagation();
                    this.deleteLocationTag(t);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  renderLinkedContent() {
    return (
      <div className="optionsContent_bottom">
        <LinkedContentSelector
          ref={(ref) => {
            this.linkedContentSelector = ref;
          }}
          updateValidation={this.updateLinkedContentValidation}
        />
      </div>
    );
  }

  renderSelectedOption() {
    const { selectedOption } = this.state;
    return (
      <div>
        <div style={{ display: selectedOption === 'audience' ? 'block' : 'none' }}>{this.renderAudience()}</div>
        <div style={{ display: selectedOption === 'publishOptions' ? 'block' : 'none' }}>{this.renderPublishOptions()}</div>
        <div style={{ display: selectedOption === 'attachments' ? 'block' : 'none' }}>{this.renderAttachments()}</div>
        <div style={{ display: selectedOption === 'featured' ? 'block' : 'none' }}>{this.renderFeatured()}</div>
        <div style={{ display: selectedOption === 'payment' ? 'block' : 'none' }}>{this.renderTicketDetails()}</div>
        <div style={{ display: selectedOption === 'organiser' ? 'block' : 'none' }}>{this.renderOrganiser()}</div>
        <div style={{ display: selectedOption === 'bookingOption' ? 'block' : 'none' }}>{this.renderBookingOptions()}</div>
        <div style={{ display: selectedOption === 'tags' ? 'block' : 'none' }}>{this.renderTags()}</div>
        <div style={{ display: selectedOption === 'linkedContent' ? 'block' : 'none' }}>{this.renderLinkedContent()}</div>
      </div>
    );
  }

  renderOptionsSection() {
    const options = [
      {
        key: 'audience',
        icon: 'audience',
        text: 'Audience',
      },
      {
        key: 'publishOptions',
        icon: 'bell',
        text: 'Publish Options',
      },
      {
        key: 'featured',
        icon: 'star',
        text: 'Featured Event',
      },
      {
        key: 'organiser',
        icon: 'user',
        text: 'Organiser',
      },
      {
        key: 'attachments',
        icon: 'attachments',
        text: 'Attachments',
      },
      {
        key: 'bookingOption',
        icon: 'pencil-o',
        text: 'Booking Option',
      },
      {
        key: 'tags',
        icon: 'tag',
        text: 'Tags',
      },
    ];
    if (this.props.siteLevel.hasLinkedContent) {
      options.push({
        key: 'linkedContent',
        icon: 'link-doc',
        text: 'Linked Content',
      });
    }
    if (this.canViewPayment()) {
      options.splice(2, 0, {
        key: 'payment',
        icon: 'dollar',
        text: 'Payment',
      });
    }
    return (
      <OptionsSection options={options} selected={this.state.selectedOption} selectOption={this.selectOption} overflowButtons>
        {this.renderSelectedOption()}
      </OptionsSection>
    );
  }

  renderSideForm() {
    return (
      <div>
        {this.renderSendAlert()}
        {this.renderConvenors()}
      </div>
    );
  }

  renderAttendanceSlots() {
    const { selectedTimeSlot } = this.state;
    const reps = this.getEventRepetitions();

    return (
      <div className="rightDivideBorder paddingRight-20" style={{ marginLeft: -30, overflow: 'scroll' }}>
        {reps.map((rep) => {
          const repText = moment(rep.Date, 'YYYY-MM-DD').format('dddd, DD MMMM YYYY');
          return (
            <p
              key={rep.Id}
              onClick={() => this.selectRepetition(rep)}
              className={`attendanceEntry ${rep.Id === selectedTimeSlot ? 'attendanceEntry-selected' : ''}`}
            >
              {repText}
            </p>
          );
        })}
      </div>
    );
  }

  renderGuestInfo(attendee, transaction) {
    const { loadingPayments, eventTicketDetails } = this.state;
    const { payment } = this.props;

    if (loadingPayments) return null;

    const attendanceCount = (attendee && attendee.Count) || 1;
    let paymentRequired = '';
    if (payment.isPaymentInfoValid(eventTicketDetails)) {
      let totalQuantity = 0;
      let transactionAmount = 0;
      if (transaction) {
        totalQuantity = payment.getTotalQuantity(payment.getPayCategories(transaction));
        transactionAmount = transaction.Amount;
      }
      if (totalQuantity === attendanceCount) {
        return (
          <span className="fontRegular fontSize-14 text-dusk">{`${totalQuantity} ticket${getPluralS(
            totalQuantity,
          )} • ${payment.formatCurrency(transactionAmount)}`}</span>
        );
      }
      paymentRequired = (
        <span className="sf-semibold fontSize-13" style={{ color: COLOUR_GRAPEFRUIT }}>
          {`Paid for ${totalQuantity} ticket${getPluralS(totalQuantity)} • ${payment.formatCurrency(transactionAmount)}`}
        </span>
      );
    }
    return attendee ? (
      <span className="fontRegular fontSize-14 text-dusk">
        {`${attendanceCount} ticket${getPluralS(attendanceCount)}${paymentRequired ? ' • ' : ''}`}
        {paymentRequired}
      </span>
    ) : null;
  }

  renderAttendeeButtons(attendee, payment, isWaitlist) {
    const { loadingPayments, cancellingAttendee, cancellingAttendeeId, eventTicketDetails } = this.state;

    const paymentExists = this.props.payment.isPaymentInfoValid(eventTicketDetails) && payment;
    const loading = loadingPayments || (cancellingAttendee && cancellingAttendeeId === attendee.id);
    const cancelText = isWaitlist ? 'Remove' : paymentExists ? 'Refund' : 'Cancel';
    return (
      <Button
        className="flex-1 textAlign-right"
        buttonType="outlined"
        onClick={() => (paymentExists ? this.onSelectRefund(attendee) : this.onRemoveUser(attendee))}
        isActive={!loading}
        compact
      >
        {loading ? <FontAwesome name="spinner fa-pulse fa-fw" /> : cancelText}
      </Button>
    );
  }

  renderAttendees() {
    const { loadingReps, eventAttendees, selectedTimeSlot } = this.state;
    const rep = eventAttendees.find((rep) => rep.RepId === selectedTimeSlot);

    if (loadingReps) {
      return (
        <div className="flex-1 flex flex-center-row flex-center">
          <FontAwesome style={{ fontSize: 30 }} name="spinner fa-pulse fa-fw" />
        </div>
      );
    }

    return (
      <div className="flex-1" style={{ overflow: 'scroll' }}>
        {rep
          ? rep.Attendees.map((att) => {
              if (att.isGuest) return null;

              const payment = this.getUserTransaction(att.id);
              return (
                <div key={att.id} className="paddingLeft-32 paddingBottom-8">
                  <div className="flex flex-row flex-center">
                    <img
                      style={{ height: 40, width: 40, borderRadius: 20, marginRight: 12 }}
                      src={att.profilePic != null ? att.profilePic : defaultProfileImage}
                      alt="user profilePic"
                    />
                    <div>
                      <div className="fontRegular fontSize-16 text-dusk">{att.displayName}</div>
                      {this.renderGuestInfo(att, payment)}
                    </div>
                    {this.renderAttendeeButtons(att, payment)}
                  </div>
                  <div className="fontRegular fontSize-13 text-dusk marginTop-8">{att.Notes}</div>
                </div>
              );
            })
          : null}
        {rep && !_.isEmpty(rep.Waitlist) ? (
          <div>
            <Text type="formTitleMedium" className="paddingLeft-32 marginTop-20 paddingBottom-8">
              Waitlist
            </Text>
            {rep.Waitlist.map((att) => {
              if (att.isGuest) return null;

              const payment = this.getUserTransaction(att.id);
              return (
                <div key={att.id} className="paddingLeft-32 paddingBottom-8">
                  <div className="flex flex-row flex-center">
                    <img
                      style={{ height: 40, width: 40, borderRadius: 20, marginRight: 12 }}
                      src={att.profilePic != null ? att.profilePic : defaultProfileImage}
                      alt="user profilePic"
                    />
                    <div>
                      <div className="fontRegular fontSize-16 text-dusk">{att.displayName}</div>
                      {this.renderGuestInfo(att, payment)}
                      <Text type="body">On waitlist from {moment.utc(att.UnixTimestamp).local().format('D MMM YY - h:mma')}</Text>
                    </div>
                    {this.renderAttendeeButtons(att, payment, true)}
                  </div>
                  <div className="fontRegular fontSize-13 text-dusk marginTop-8">{att.Notes}</div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }

  renderAttendancePopup() {
    const { selectedTimeSlot } = this.state;
    if (_.isNil(selectedTimeSlot)) return null;

    return (
      <Popup
        boxClasses="flex flex-column"
        innerClasses="flex flex-column fillSpace flex-1"
        maxWidth={800}
        minWidth={800}
        minHeight={'80vh'}
        title="Attendance"
        hasPadding
        onClose={this.onCloseAttendance}
        buttons={[
          {
            type: 'tertiary',
            onClick: this.onCloseAttendance,
            isActive: true,
            text: 'Cancel',
          },
          {
            type: 'outlined',
            onClick: this.onOpenCSV,
            isActive: this.isReadyToOpenCSV(),
            text: 'Export CSV',
            leftIcon: 'file-code-o',
            buttonStyle: { marginRight: 'auto' },
          },
          {
            type: 'outlined',
            onClick: this.onAddAttendees,
            isActive: true,
            text: 'Add Attendees',
          },
        ]}
      >
        <div className="flex flex-row flex-1" style={{ overflowX: 'visible', overflowY: 'clip' }}>
          {this.renderAttendanceSlots()}
          {this.renderAttendees()}
        </div>
      </Popup>
    );
  }

  renderAttendeeSearch() {
    const { eventAttendees, attendeeSearch, selectedTimeSlot, selectedAttendee, savingAttendee } = this.state;
    const { users } = this.props;

    const repAttendees = eventAttendees.find((rep) => rep.RepId === selectedTimeSlot);
    const attendees = repAttendees && repAttendees.Attendees ? repAttendees.Attendees : [];
    const contacts = _.orderBy(
      _.filter(users, (u) => {
        return (
          u.category === 'resident' &&
          (!attendeeSearch || u.displayName.toLowerCase().indexOf(attendeeSearch.toLowerCase()) > -1) &&
          attendees.findIndex((att) => att.id === u.Id) < 0
        );
      }),
      'displayName',
    );

    return (
      <div style={{ minWidth: 340, backgroundColor: '#f4f7f9', padding: 18, overflowY: 'scroll' }}>
        <div className="flex flex-row flex-center paddingBottom-4 bottomDivideBorder">
          <FontAwesome name="search" className="text-loginGrey fontSize-20" />
          <GenericInput
            id="attendeeSearch"
            type="text"
            placeholder="Search"
            value={attendeeSearch}
            onChange={(e) => this.handleChange(e)}
            style={{ marginBottom: 'unset', marginLeft: 10 }}
            inputStyle={{ borderBottom: 'unset' }}
          />
        </div>
        <div>
          {contacts.map((user) => {
            const isSelected = selectedAttendee && selectedAttendee.Id === user.Id;
            return (
              <div key={user.Id} className="flex flex-row flex-center paddingTop-8 paddingBottom-8">
                <CheckBox
                  disabled={savingAttendee}
                  isActive={isSelected}
                  onChange={() => this.onSelectAttendee(user)}
                  style={{ marginBottom: 'unset' }}
                />
                <ProfilePic size={40} style={{ marginLeft: 12, marginRight: 12 }} image={user.profilePic} />
                <div className="fontRegular fontSize-14 text-dusk">{user.displayName}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderTicketIcon() {
    return (
      <div
        className="flex flex-center flex-center-row borderRadius-2"
        style={{ backgroundColor: hexToRGBAstring(COLOUR_BRANDING_MAIN, 0.2), width: 50, height: 50 }}
      >
        <SVGIcon colour={COLOUR_BRANDING_MAIN} icon="tickets" width="32px" height="32px" />
      </div>
    );
  }

  renderAttendanceDetails() {
    const { selectedAttendee, attendanceCount, attendanceNotes, loadingSpots, spotsAvailable, savingAttendee } = this.state;

    return (
      <div className="flex-1 divideBorder padding-18 marginLeft-24" style={{ minWidth: 370, overflowY: 'scroll', position: 'relative' }}>
        <Text type="formTitleMedium">Attendance Details</Text>
        {selectedAttendee ? (
          <div className="flex flex-column">
            <div className="flex flex-row flex-center marginTop-10">
              <ProfilePic size={40} style={{ marginRight: 12 }} image={selectedAttendee.profilePic} />
              <div className="fontRegular fontSize-14 text-dusk">{selectedAttendee.displayName}</div>
            </div>
            <div className="flex flex-row flex-center marginTop-20">
              {this.renderTicketIcon()}
              <Text type="formTitleSmall" className="marginLeft-10 flex-1">
                Tickets
              </Text>
              {!loadingSpots ? (
                <NumberSpinner
                  value={attendanceCount}
                  onPlus={this.onChangeAttendanceCount}
                  onMinus={this.onChangeAttendanceCount}
                  max={spotsAvailable}
                  min={0}
                  disabled={savingAttendee}
                />
              ) : (
                <div className="marginRight-24">
                  <FontAwesome name="spinner fa-pulse fa-fw" />
                </div>
              )}
            </div>
            <GenericInput
              id="attendanceNotes"
              type="textarea"
              label="Notes"
              placeholder="Insert notes here"
              value={attendanceNotes}
              onChange={(e) => this.handleChange(e)}
              alwaysShowLabel
              style={{ position: 'absolute', bottom: 10, width: '90%' }}
              disabled={savingAttendee}
            />
          </div>
        ) : (
          <div className="flex flex-column flex-center flex-center-row" style={{ height: '90%' }}>
            <Text type="body" className="text-centered">
              Please select the attendee to
              <br />
              configure attendance details
            </Text>
          </div>
        )}
      </div>
    );
  }

  renderAddAttendeesPopup() {
    const { selectedTimeSlot, savingAttendee } = this.state;
    if (_.isNil(selectedTimeSlot)) return null;

    return (
      <Popup
        boxClasses="flex flex-column"
        innerClasses="flex flex-column fillSpace flex-1"
        maxWidth={800}
        minWidth={800}
        minHeight={'80vh'}
        title="Add Attendees"
        hasPadding
        onClose={this.onCloseAttendance}
        buttons={[
          {
            type: 'primaryAction',
            onClick: this.onSaveAttendance,
            isActive: this.isAttendeeValid(),
            text: 'Save',
            style: { width: 90 },
            loading: savingAttendee,
          },
          {
            type: 'tertiary',
            onClick: this.onCloseAddAttendees,
            isActive: true,
            text: 'Back to Attendance',
          },
        ]}
      >
        <div className="flex flex-row fillSpace flex-1">
          {this.renderAttendeeSearch()}
          {this.renderAttendanceDetails()}
        </div>
      </Popup>
    );
  }

  renderRefund() {
    const { ticketsEntered, cancellingAttendee, selectedTransaction, onchargeFees } = this.state;
    const { payment } = this.props;

    if (!selectedTransaction) return null;

    const ticketCategories = payment.getPayCategories(selectedTransaction);
    const original = payment.getTotalPrice(ticketCategories);
    const subtotal = original - payment.getTotalPrice(ticketsEntered);
    const fee = onchargeFees ? 0 : payment.getFee(selectedTransaction.Fee, subtotal) * -1;
    return (
      <div className="flex-1 marginTop-16">
        <div className="sf-semibold fontSize-16 text-dusk">Select the tickets that you want to refund</div>
        <div className="flex flex-column marginTop-20">
          {ticketCategories.map((cat) => {
            const ticket = ticketsEntered.find((t) => t.category === cat.category);
            return (
              <div key={cat.category} className="marginBottom-16 flex flex-row flex-center">
                {this.renderTicketIcon()}
                <div className="marginLeft-16 flex-1">
                  <div className="sf-semibold fontSize-13 text-dusk">{`${cat.category}${
                    cat.quantity > 0 ? ` (${cat.quantity})` : ''
                  }`}</div>
                  <div className="text-bold fontSize-18 text-brandingColour">{payment.formatCurrency(cat.price)}</div>
                </div>
                <NumberSpinner
                  value={ticket ? ticket.quantity : 0}
                  onPlus={(newQuantity) => this.onChangeRefundCount(cat.category, cat.price, newQuantity)}
                  onMinus={(newQuantity) => this.onChangeRefundCount(cat.category, cat.price, newQuantity)}
                  max={cat.quantity || 0}
                  min={0}
                  disabled={cancellingAttendee}
                />
              </div>
            );
          })}
        </div>
        <div className="topDivideBorderThin marginTop-16">
          <div className="flex flex-row flex-center flex-between paddingTop-8">
            <div className="fontRegular fontSize-14 text-bluegrey">Subtotal</div>
            <div className="fontRegular fontSize-14 text-bluegrey">{payment.formatCurrency(subtotal)}</div>
          </div>
          <div className="flex flex-row flex-center flex-between paddingTop-8 paddingBottom-8">
            <div className="fontRegular fontSize-14 text-bluegrey">Fee</div>
            <div className="fontRegular fontSize-14 text-bluegrey">{payment.formatCurrency(fee === 0 ? Math.abs(fee) : fee)}</div>
          </div>
          <div className="flex flex-row flex-center flex-between paddingTop-18 paddingBottom-4">
            <div className="sf-semibold fontSize-16 text-dusk">Total Refundable</div>
            <div className="sf-semibold fontSize-20 text-dusk">{payment.formatCurrency(subtotal + fee)}</div>
          </div>
          <div className="flex flex-row flex-center flex-between paddingBottom-8">
            <div className="genericInput-help">
              {onchargeFees ? 'Fee has already been paid by the user' : 'This excludes fees paid by the connected account'}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderRefundPopup() {
    const { cancellingAttendee, attendeeToRefund } = this.state;
    if (!attendeeToRefund) return null;

    const canRefund = !cancellingAttendee && this.hasTicketsChanged();
    return (
      <Popup
        boxClasses="flex flex-column"
        innerClasses="flex flex-column fillSpace flex-1"
        maxWidth={600}
        minWidth={600}
        title="Refund"
        hasPadding
        onClose={this.onCloseAttendance}
        buttons={[
          {
            type: 'primaryAction',
            onClick: () => this.onRemoveUser(attendeeToRefund, this.onCloseRefund),
            isActive: canRefund,
            text: 'Refund',
            style: { width: 90 },
            loading: cancellingAttendee,
          },
          {
            type: 'tertiary',
            onClick: this.onCloseRefund,
            isActive: true,
            text: 'Cancel',
          },
        ]}
      >
        <div className="flex flex-row fillSpace flex-1" style={{ overflow: 'scroll' }}>
          {this.renderRefund()}
        </div>
      </Popup>
    );
  }

  renderRefundTransactions() {
    const { refundTransactions } = this.state;
    const { payment } = this.props;

    return (
      <div className="flex-1">
        <div className="sf-semibold fontSize-16 text-dusk">Select the transaction that you want to refund</div>
        <div className="marginTop-16">
          {refundTransactions.map((p) => {
            const time = moment(p.UnixTimestamp);
            const ticketQuantity = payment.getTotalQuantity(payment.getPayCategories(p));
            return (
              <div key={p.RowId} className="padding-8 paddingLeft-2 paddingRight-2" onClick={() => this.onSelectRefundTransaction(p)}>
                <div className="genericBoxShadow flex flex-row flex-center padding-10">
                  <div>
                    <div className="sf-semibold fontSize-14 text-dusk marginBottom-5">
                      Transaction - {time.format('DD MMM YYYY, h:mma').toUpperCase()}
                    </div>
                    <div className="sf-semibold fontSize-16 text-brandingColour">{`${ticketQuantity} ticket${getPluralS(
                      ticketQuantity,
                    )}`}</div>
                  </div>
                  <div className="flex-1 textAlign-right text-bold fontSize-20 text-brandingColour">{payment.formatCurrency(p.Amount)}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderSelectTransactionPopup() {
    const { cancellingAttendee, attendeeToRefund } = this.state;
    if (!attendeeToRefund) return null;

    return (
      <Popup
        boxClasses="flex flex-column"
        innerClasses="flex flex-column fillSpace flex-1"
        maxWidth={600}
        minWidth={600}
        title="Refund"
        hasPadding
        onClose={this.onCloseAttendance}
        buttons={[
          {
            type: 'primaryAction',
            onClick: () => this.onRemoveUser(attendeeToRefund, this.onCloseSelectTransaction, true),
            isActive: !cancellingAttendee,
            text: 'Refund All',
            style: { width: 120 },
            loading: cancellingAttendee,
          },
          {
            type: 'tertiary',
            onClick: this.onCloseSelectTransaction,
            isActive: true,
            text: 'Back to Attendance',
          },
        ]}
      >
        <div className="flex flex-row fillSpace flex-1" style={{ overflow: 'scroll' }}>
          {this.renderRefundTransactions()}
        </div>
      </Popup>
    );
  }

  renderMakerRepSelectorPopup() {
    if (!this.state.makerRepSelectorOpen) {
      return null;
    }
    return (
      <Popup
        title="Select time slot"
        onClose={this.closeMakerRepSelector}
        hasPadding
        minWidth={600}
        buttons={[
          {
            type: 'tertiary',
            onClick: this.closeMakerRepSelector,
            isActive: true,
            text: 'Cancel',
          },
        ]}
      >
        {_.filter(this.state.eventRepetitions, (ev) => {
          return !ev.Removed;
        }).map((rep) => {
          return (
            <div
              onClick={() => {
                this.openMaker(rep);
              }}
              key={rep.Id}
              className="listEntry"
            >
              {this.getMakerRepText(rep)}
            </div>
          );
        })}
      </Popup>
    );
  }

  renderMakerPopup() {
    if (!this.state.makerOpen) {
      return null;
    }
    return (
      <MakerPopup
        title="Create Poster"
        onClose={this.closeMaker}
        initialData={this.state.makerData}
        templateId="fabdce66-f41e-4fcc-abf6-f20ed78c0da8"
        minWidth={600}
        inputs={[
          {
            key: 'title',
            type: 'text',
            title: 'Event Title',
          },
          {
            key: 'description',
            type: 'textarea',
            title: 'Description',
          },
          {
            key: 'timeText',
            type: 'text',
            title: 'Event Time',
          },
          {
            key: 'image',
            type: 'text',
            title: 'Event Image',
            readOnly: true,
          },
        ]}
      />
    );
  }

  renderCSVPopup() {
    const source = this.getExportSource();
    if (!this.state.isCSVOpen) {
      return null;
    }
    return (
      <ExportCsvPopup
        onClose={this.onCloseCSV}
        columns={this.getExportColumns()}
        source={source}
        filename={`${this.getExportTitle()}.csv`}
      />
    );
  }

  render() {
    const {
      addingAttendees,
      attendeeToRefund,
      selectedTransaction,
      sendAlertOpen,
      eventIdWithoutSite,
      eventTitle,
      eventAttendees,
      loadingReps,
    } = this.state;

    const renderAttendee = () => {
      if (addingAttendees) {
        return this.renderAddAttendeesPopup();
      } else if (attendeeToRefund) {
        return selectedTransaction ? this.renderRefundPopup() : this.renderSelectTransactionPopup();
      }
      return this.renderAttendancePopup();
    };
    const reps = this.getEventRepetitions();

    return (
      <OverlayPage>
        <TextFormatPopup onClose={this.toggleTextFormat.bind(this, false)} isOpen={this.state.textFormatOpen} />
        <OverlayPageContents id="EventContainer" noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--newPopup pageSectionWrapper--newPopup950" onScroll={this.onScrollPage}>
            {this.renderForm()}
            {this.renderBroadcasterSelector()}
            {this.renderSuccess()}
          </OverlayPageSection>
          {!this.state.success && !this.state.isEveryday && (
            <OverlayPageSection className="pageSectionWrapper--newPopupSide">{this.renderSideForm()}</OverlayPageSection>
          )}
          {this.renderConvenorSide()}
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderSubmit()}</OverlayPageBottomButtons>
        {renderAttendee()}
        {this.renderCSVPopup()}
        {this.renderMakerPopup()}
        {this.renderMakerRepSelectorPopup()}
        {sendAlertOpen ? (
          <SendAlertPopup
            onClose={this.onCloseSendAlert}
            eventId={eventIdWithoutSite}
            eventTitle={eventTitle}
            reps={reps}
            attendees={eventAttendees}
            loadingAttendees={loadingReps}
          />
        ) : null}
      </OverlayPage>
    );
  }
}

const mapStateToProps = (state) => {
  const { events, facilities, managed, globals, templates } = state.events;
  const { auth, users } = state;
  const merchants = getMerchantsFromState(state);

  return {
    events,
    facilities,
    globals,
    templates,
    auth,
    users: users.allUsers,
    managedEvents: managed,
    merchants,
    commentVisibilityLimited: getSiteSettingFromState(state, 'CommentVisibilityLimited'),
    siteLevel: getSiteLevelFromState(state),
  };
};

export default withPay(connect(mapStateToProps, { eventsLoaded, eventsUpdate, usersLoaded, addRecentlyCreated })(AddEvent));
