import React, { Component } from 'react';
import _ from 'lodash';
import { GenericInput, CheckBox, P60Icon, Button } from '../../components';
import { COLOUR_GREEN } from '../../js';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { checkLoggedIn, validateAccess } from '../../session';
import { userActions } from '../../webapi';

const DEFAULT_FIELD = { label: '', staffOnly: false, editable: false };

class UserProfiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [],
      loading: false,
      submitting: false,
      success: false,
    };
  }

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
  }

  componentDidMount() {
    if (!validateAccess(this.props.auth.site, 'userManagement', this.props.auth, true)) {
      this.props.history.push('/mastermenu');
    } else {
      this.getProfileConfig();
    }
  }

  getProfileConfig = () => {
    this.setState({ loading: true }, async () => {
      try {
        const { data } = await userActions.getProfileFieldConfig(this.props.auth.site);
        this.setState({ fields: data || [] });
      } catch (error) {
        console.log('getProfileConfig - error', error);
      } finally {
        this.setState({ loading: false });
      }
    });
  };

  validateForm() {
    const { loading, submitting, fields } = this.state;
    if (loading || submitting) return false;

    // Validate fields
    const fieldsValid = fields.map((field) => {
      const { label } = field;
      return !_.isEmpty(label);
    });
    if (!fieldsValid.every((valid) => valid)) return false;

    return true;
  }

  onAddNewField = () => {
    const fields = [...this.state.fields];
    fields.push({ ...DEFAULT_FIELD });
    this.setState({ fields });
  };

  onRemoveField = (fieldIndex) => {
    const fields = [...this.state.fields];
    if (fields.length < 2) return;
    fields.splice(fieldIndex, 1);
    this.setState({ fields });
  };

  onFieldLabelChanged = (fieldIndex, event) => {
    const fields = [...this.state.fields];
    fields[fieldIndex].label = event.target.value;
    this.setState({ fields });
  };

  onFieldStaffOnlyChanged = (fieldIndex) => {
    const fields = [...this.state.fields];
    fields[fieldIndex].staffOnly = !fields[fieldIndex].staffOnly;
    this.setState({ fields });
  };

  onFieldEditableChanged = (fieldIndex) => {
    const fields = [...this.state.fields];
    fields[fieldIndex].editable = !fields[fieldIndex].editable;
    this.setState({ fields });
  };

  onMoveFieldPrev = (fieldIndex) => {
    if (fieldIndex === 0) return;
    const fields = [...this.state.fields];
    const item = fields.splice(fieldIndex, 1)[0];
    fields.splice(fieldIndex - 1, 0, item);

    this.setState({ fields });
  };

  onMoveFieldNext = (fieldIndex) => {
    const fields = [...this.state.fields];
    if (fieldIndex > fields.length - 1) return;
    const item = fields.splice(fieldIndex, 1)[0];
    fields.splice(fieldIndex + 1, 0, item);

    this.setState({ fields });
  };

  onSaveForm = () => {
    if (!this.validateForm()) return;
    this.setState({ success: false, submitting: true }, async () => {
      try {
        const { data } = await userActions.updateProfileFieldConfig(this.props.auth.site, this.state.fields);
        this.setState({ fields: data.updated || [], success: true, submitting: false });
      } catch (error) {
        console.log('onSaveForm error', error);
        this.setState({ success: false, submitting: false });
      }
    });
  };

  renderFieldText(field, fieldIndex) {
    return (
      <div className="fieldInner">
        <GenericInput
          id={`fieldLabel${fieldIndex}`}
          className={'textInput'}
          placeholder={'Label your field'}
          value={field.label}
          onChange={(e) => this.onFieldLabelChanged(fieldIndex, e)}
        />
        <CheckBox
          id={`fieldStaffOnly${fieldIndex}`}
          label="Only visible by staff"
          isActive={field.staffOnly}
          onChange={(e) => this.onFieldStaffOnlyChanged(fieldIndex, e)}
        />
        <CheckBox
          id={`fieldEditable${fieldIndex}`}
          label="Residents can fill in this field from the app"
          isActive={field.editable}
          onChange={(e) => this.onFieldEditableChanged(fieldIndex, e)}
        />
      </div>
    );
  }

  renderField(field, fieldIndex) {
    const { fields } = this.state;

    return (
      <div className="fieldContainer" key={fieldIndex}>
        <div className="fieldNumberContainer">
          <p className="fieldNumber">{fieldIndex + 1}</p>
        </div>
        <div className="field">
          <div className="fieldHeader">
            <div className="group">
              <div className="line" />
              <div className="fieldType">{'Text Input'}</div>
            </div>
            <div className="group">
              {fields.length > 1 && (
                <div className="delete" onClick={() => this.onRemoveField(fieldIndex)}>
                  Delete
                </div>
              )}
            </div>
          </div>
          {this.renderFieldText(field, fieldIndex)}
        </div>
        {fields.length > 1 && (
          <div className="switchField">
            {fieldIndex > 0 ? (
              <div className="circle" onClick={() => this.onMoveFieldPrev(fieldIndex)}>
                <FontAwesome name="angle-up" className="icon" />
              </div>
            ) : null}
            {fieldIndex < fields.length - 1 ? (
              <div className="circle" onClick={() => this.onMoveFieldNext(fieldIndex)}>
                <FontAwesome name="angle-down" className="icon" />
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }

  renderFields() {
    const { fields } = this.state;

    return (
      <div className="addForm">
        <div className="fields">
          {fields.map((field, fieldIndex) => this.renderField(field, fieldIndex))}
          <div className="clearfix addoption addField" onClick={() => this.onAddNewField()}>
            <P60Icon className="addoption_plus" icon="add-circle" />
            <div className="fillSpace">
              <p className="addoption_text">Add New Field</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <Button inline buttonType="primary" onClick={this.onSaveForm} isActive={this.validateForm()}>
        Save
      </Button>
    );
  }

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return <span style={{ color: COLOUR_GREEN, fontSize: 14, lineHeight: '33px', marginLeft: 30 }}>Saved</span>;
  }

  render() {
    return (
      <div style={{ minWidth: '100%' }}>
        <p className="fontMedium fontSize-36 text-dark">Set up Profile Information</p>
        {this.renderFields()}
        <div style={{ paddingTop: 24, paddingBottom: 24 }}>
          {this.renderSubmit()}
          {this.renderSuccess()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {})(withRouter(UserProfiles));
